import React from 'react';
import './App.css'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import store from './store'

class TestingComplete extends React.Component {
	
	componentDidMount() {	
		const { entryCode } = this.props
		const storeState = store.getState()
		this.targetElement = document.querySelector('.ButtonStrip');
		//disableBodyScroll(this.targetElement) //lock/prevent page scrolling so that the "game board" doesn't move when accidentally dragging the screen during testing
		document.body.ontouchmove = (e) => { e.preventDefault(); return false; }

		this.dataStream = JSON.stringify({ 
			name: entryCode ,
		    data: { 'platform': window.navigator.userAgent, ...storeState.auto }
		  })
	  	const sendStartTime = Date.now()
	  	return fetch('https://0dmiwnt6r8.execute-api.us-east-1.amazonaws.com/default/iBoca3Upload', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json'},
			body: this.dataStream
		}).then( result => console.log("SEND COMPLETE"))
	}

	render = () => {
		return(
			<Modal centered isOpen={true}>
				<ModalHeader>
					<h3> Testing Complete </h3>
				</ModalHeader>
	            <ModalBody className="text-center">
	                <p className="my-2"> Thank you for participating in this study. You can now close this window </p>
	            </ModalBody>
	       </Modal>
		)
	}
}


export default TestingComplete;