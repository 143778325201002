import React from 'react'
import { Modal, ModalBody, ModalFooter, Button} from 'reactstrap';
import { Link } from 'react-router-dom';

class Reset extends React.Component {

	reload = () => {
		window.location.replace(window.location.origin + '?mode=' + this.props.testMode) 
	}

	render = () => {
		return (
			<Modal centered={true} isOpen={true}>
	         <ModalBody className="text-center"> 
			 	Are you sure you'd like to reset?
	         </ModalBody>
	         <ModalFooter className="d-flex justify-content-center">
	            <Button color="danger" onClick={this.reload}>Yes</Button>
	            <Link to='/'>
	            	<Button color="primary">No</Button>
				</Link>
	         </ModalFooter>
	     	</Modal>
		)
	}
}

export default Reset