import koala from '../imgs/Animals/koala.jpg'
import ostrich from '../imgs/Animals/ostrich.jpg'
import panda from '../imgs/Animals/panda.jpg'
import walrus from '../imgs/Animals/walrus.jpg'
import daisy from '../imgs/Flowers/daisy.jpg'
import lily from '../imgs/Flowers/lily.jpg'
import sunflower from '../imgs/Flowers/sunflower.jpg'
import tulip from '../imgs/Flowers/tulip.jpg'
import pickaxe from '../imgs/Garden/pickaxe.jpg'
import pitchfork from '../imgs/Garden/pitchfork.jpg'
import trowel from '../imgs/Garden/trowel.jpg'
import wheelbarrow from '../imgs/Garden/wheelbarrow.jpg'
import corkscrew from '../imgs/Kitchen/corkscrew.jpg'
import funnel from '../imgs/Kitchen/funnel.jpg'
import ladle from '../imgs/Kitchen/ladle.jpg'
import tongs from '../imgs/Kitchen/tongs.jpg'
import harmonica from '../imgs/Musical instruments/harmonica.jpg'
import tambourine from '../imgs/Musical instruments/tambourine.jpg'
import tuba from '../imgs/Musical instruments/tuba.jpg'
import xylophone from '../imgs/Musical instruments/xylophone.jpg'
import centaur from '../imgs/Mythical/centaur.jpg'
import pegasus from '../imgs/Mythical/pegasus.jpg'
import sphinx from '../imgs/Mythical/sphinx.jpg'
import unicorn from '../imgs/Mythical/unicorn.jpg'
import chisel from '../imgs/Tools/chisel.jpg'
import level from '../imgs/Tools/level.jpg'
import plane from '../imgs/Tools/plane.jpg'
import pliers from '../imgs/Tools/pliers.jpg'
import artichoke from '../imgs/Vegetables/artichoke.jpg'
import brusselsprout from '../imgs/Vegetables/brussel sprout.jpg'
import eggplant from '../imgs/Vegetables/eggplant.jpg'
import okra from '../imgs/Vegetables/okra.jpg'
	
export default [[koala, ostrich, panda, walrus],
    			[daisy, lily, sunflower, tulip],
	    		[pickaxe, pitchfork, trowel, wheelbarrow],
		    	[corkscrew, funnel, ladle, tongs],
			    [harmonica, tambourine, tuba, xylophone],
    			[centaur, pegasus, sphinx, unicorn],
	    		[chisel, level, plane, pliers],
		    	[artichoke, brusselsprout, eggplant, okra]]


                
