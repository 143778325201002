import { AUTO_GROUP_ENTERED, AUTO_NAME_ENTERED, AUTO_AGE_ENTERED, AUTO_GENDER_ENTERED, AUTO_RACE_ENTERED, AUTO_YEARS_EDU_ENTERED, AUTO_DCOG_INCREMENT, AUTO_DCOG_SHORT_COMPLETED, AUTO_DCOG_LONG_COMPLETED } from '../actions/ActionTypes'

export function autoGroupEntered(data) {
	return {
		type: AUTO_GROUP_ENTERED,
		data: data
	}
}

export function autoNameEntered(data) {
	return {
		type: AUTO_NAME_ENTERED,
		data: data
	}
}

export function autoAgeEntered(data) {
	return {
		type: AUTO_AGE_ENTERED,
		data: data
	}
}

export function autoGenderEntered(data) {
	return {
		type: AUTO_GENDER_ENTERED,
		data: data
	}
}

export function autoRaceEntered(data) {
	return {
		type: AUTO_RACE_ENTERED,
		data: data
	}
}

export function autoYearsEduEntered(data) {
	return {
		type: AUTO_YEARS_EDU_ENTERED,
		data: data
	}
}

export function autoDCogIncrement() {
	return {
		type: AUTO_DCOG_INCREMENT
	}
}

export function autoDCogShortComplete(data) {
	return {
		type: AUTO_DCOG_SHORT_COMPLETED,
		data: data
	}
}

export function autoDCogLongComplete(data) {
	return {
		type: AUTO_DCOG_LONG_COMPLETED,
		data: data
	}
}