export const CAD_COMPLETE = 'CAD_COMPLETE'
export const CPT_COMPLETE = 'CPT_COMPLETE'
export const CADNOMEM_COMPLETE = 'CADNOMEM_COMPLETE'
export const TMT_COMPLETE = 'TMT_COMPLETE'
export const MEM_COMPLETE = 'MEM_COMPLETE'
export const AUTO_GROUP_ENTERED = 'AUTO_GROUP_ENTERED'
export const AUTO_NAME_ENTERED = 'AUTO_NAME_ENTERED'
export const AUTO_AGE_ENTERED = 'AUTO_AGE_ENTERED'
export const AUTO_GENDER_ENTERED = 'AUTO_GENDER_ENTERED'
export const AUTO_RACE_ENTERED = 'AUTO_RACE_ENTERED'
export const AUTO_YEARS_EDU_ENTERED = 'AUTO_YEARS_EDU_ENTERED'
export const AUTO_DCOG_INCREMENT = 'AUTO_DCOG_INCREMENT'
export const AUTO_DCOG_SHORT_COMPLETED = 'AUTO_DCOG_SHORT_COMPLETED'
export const AUTO_DCOG_LONG_COMPLETED = 'AUTO_DCOG_LONG_COMPLETED'