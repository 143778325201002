import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Input } from 'reactstrap';
import { MdMoodBad, MdSentimentDissatisfied, MdSentimentNeutral, MdSentimentSatisfied, MdMood } from "react-icons/md";
import { Link } from 'react-router-dom';

class AdminQuestionnaire extends React.Component {
	constructor(props) {
		super(props);
        this.state = { 
        	q1: null,
        	text: ''
        }
	}

	setQuestion = (q, key) => {
		this.setState({[q]: key})
	}

	inputChange = (e) => {
    	this.setState({ text: e.target.value });
  	}

	questions = [
		{'q_num' : 'q1', 'q_text' : 'how easy was this test to administer?', 'high': 'very easy', 'low': 'very difficult'},
	]

	sendData = () => {
		const { entryCode } = this.props
		const data = JSON.stringify({
			'name': entryCode + '-questionnaire', 
			'data': {'questions' : this.questions, ...this.state}
		})
		return fetch('https://0dmiwnt6r8.execute-api.us-east-1.amazonaws.com/default/iBoca3Upload', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json'},
			body: data
		}).then(result => console.log(result))
	}

	render = () => {
		const styleGreat = {
			color: "#44ff00"
		}
		const styleGood = {
			color: "#b3ff00"
		}
		const styleNeutral = {
			color: "#fff700"
		}
		const styleNotGood = {
			color: "#ff8400"
		}
		const styleBad = {
			color: "#ff0000"
		}

		return(
			<Modal centered={true} isOpen={true} size={'lg'}>
				<ModalHeader className="d-flex justify-content-center">
					Questionnaire
				</ModalHeader>
		        <ModalBody>
		        	{this.questions.map((q_value, q_index) => {
						return(
							<>
							<Row className="d-flex m-3">
								<Col md="3">
									<b>{q_value.q_text}</b>
								</Col>
								<Col className="justify-content-center">
									<Button className="" onClick={() => this.setQuestion(q_value.q_num, 1)} active={this.state[q_value.q_num] === 1}>
										<MdMood size="4em" className="mx-2" style={styleGreat}/>
									</Button>
								</Col>
								<Col className="justify-content-center">
									<Button className="" onClick={() => this.setQuestion(q_value.q_num, 2)} active={this.state[q_value.q_num] === 2}>
										<MdSentimentSatisfied size="4em" className="mx-2" style={styleGood}/>
									</Button>
								</Col>
								<Col className="justify-content-center">
									<Button className="" onClick={() => this.setQuestion(q_value.q_num, 3)} active={this.state[q_value.q_num] === 3}>
										<MdSentimentNeutral size="4em" className="mx-2" style={styleNeutral}/>
									</Button>
								</Col>
								<Col className="justify-content-center">
									<Button className="" onClick={() => this.setQuestion(q_value.q_num, 4)} active={this.state[q_value.q_num] === 4}>
										<MdSentimentDissatisfied size="4em" className="mx-2" style={styleNotGood}/>
									</Button>
								</Col>
								<Col className="justify-content-center">
									<Button className="" onClick={() => this.setQuestion(q_value.q_num, 5)} active={this.state[q_value.q_num] === 5}>
										<MdMoodBad size="4em" className="mx-2" style={styleBad}/>
									</Button>
								</Col>
	     					 </Row>
	     					 <hr/>
	     					 </>
	     				)
		        	})}
		        	<Row className="d-flex justify-content-center">
		        		<h5>Additional comments from either patient or administrator</h5>
		        		<Input className="mx-3" type="textarea" name="text" onChange={this.inputChange}/>
		        	</Row>
		        </ModalBody>
		        <ModalFooter className="d-flex justify-content-center">
					<Link to='/' onClick={this.sendData}>
						<Button color="success">Send</Button>
					</Link>
					<Link to='/'>
						<Button color="primary">Close</Button>
					</Link>
				</ModalFooter>
		    </Modal>
		)
	}
}

export default AdminQuestionnaire