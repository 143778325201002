import React from 'react'
import * as BSIcons from 'react-icons/bs';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Row, Col, Collapse} from 'reactstrap';
import { Link } from 'react-router-dom';
import store from './store'
import routes from './routes'

class Results extends React.Component {
	constructor(props) {
		super(props);
		this.state = {}
		routes.test_routes.forEach(value => {
			const dataToggle = "dataToggle"+value.path.substring(1)
			this.setState({[dataToggle] : false})
		})
	}

	showDataToggle = (key) => { 
		this.setState({[key]: !this.state[key]}) 
	}

	renderTestResult = (testName, data) => {
		const hasData = data.length !== 0
		if(hasData){
			const summaryData = (testName==='TMT') ? ['A1:',data[3],'A2:',data[6],'B:',data[9],'Overall',data[10]] : data[data.length-1]
			return(
				<Row className="border rounded p-3">				 
						<Col md="4">
							<b>{testName}</b>
							<BSIcons.BsCheckCircle size="1.5em" className="mx-2"/>
						</Col>
						<Col md="4">
							<code>{data[0].date.slice(0,25)}<br/>{data[0].ver}</code>
						</Col>
						<Col md="4">
							<Button color="secondary" block="true" onClick={() => this.showDataToggle("dataToggle"+testName)}>
			        			{this.state["dataToggle"+testName] ? "Hide Data" : "View Full Data Stream"}
								{this.state["dataToggle"+testName] ? <BSIcons.BsFillCaretUpFill size="1.5em" className="mx-2"/> : <BSIcons.BsFillCaretDownFill size="1.5em" className="mx-2"/>}
							</Button>
						</Col>
						<code style={{ color: 'blue' }}><br/><b>Summary: </b>{JSON.stringify( summaryData ).replace(/,/g, ', ')}<br/></code>
						<Collapse isOpen={this.state["dataToggle"+testName]}>
			        		<div className="bg-light">
			        			{data.map((value, index) => {
									return(
										<code className='m-2'>{JSON.stringify({value})}</code>
									)
								})}
			        		</div>
			        	</Collapse>
				</Row>
			)
		} else {
			return(
				<Row className="border rounded p-3">				 
					<Col md="4">
						<b>{testName}</b>
						<BSIcons.BsExclamationCircleFill size="1.5em" className="mx-2"/>
					</Col>
					<Col md="8 d-flex justify-content-center">
						<p>No data available</p>
					</Col>
				</Row>
			)
		}
	}

	render() {
		const data = store.getState()
		return(
			<Modal centered={true} isOpen={true} size='xl'>
				<ModalHeader className="d-flex justify-content-center"> Test Results </ModalHeader>
				<ModalBody>
					{routes.test_routes.map((value, index) => {
						return(this.renderTestResult(value.text, data[value.path.substring(1)]))
					})}
				</ModalBody>
				<ModalFooter className="d-flex justify-content-center">
					<Link to='/'>
						<Button color="primary">OK</Button>
					</Link>
				</ModalFooter>
			</Modal>
		)
	}

	// render = () => {
	// 	function makeText1(x) {
	// 		const j = x.length;  
	// 		const y = (j===0) ? '[No available data]' : '[Test info from 1st frame of data output: ' + x[0] + ']'
	// 		return y
	// 	}
	// 	function makeText2(x) {
	// 		const j = x.length;  
	// 		const y = (j===0) ? '' : JSON.stringify( x[j-1] )
	// 		return y.slice(0,79) + ' ' + y.slice(79)
	// 	}
	// 	function makeTextTMT(x) {
	// 		const j = x.length;  
	// 		const y = (j===0) ? '' : JSON.stringify( x[3] ) + ' ' + JSON.stringify( x[6] ) + ' ' + JSON.stringify( x[9] ) + ' ' + JSON.stringify( x[10] )
	// 		return y
	// 	}
	// 	const dat = store.getState()
	// 	return(
	// 		<Modal centered={true} isOpen={true} size='lg'>
	// 			  <ModalHeader><b>Results Summary [v1.02]</b></ModalHeader>
	// 			  <ModalBody className="text-center">
	// 				  <b>D-Cog (w/ mem):</b> {makeText1(dat.catsanddogs)}<br/>{makeText2(dat.catsanddogs)}<br/><br/>
	// 				  <b>D-Cog:</b> {makeText1(dat.catsanddogsnomem)}<br/>{makeText2(dat.catsanddogsnomem)}<br/><br/>
	// 				  <b>TMT:</b> {makeText1(dat.tmt)}<br/>{makeTextTMT(dat.tmt)}<br/><br/>
	// 				  <b>CPT:</b> {makeText1(dat.cpt)}<br/>{makeText2(dat.cpt)}<br/><br/>
	// 			  </ModalBody>
	// 			  <ModalFooter className="d-flex justify-content-center">
	// 			    <Link to='/'>
	// 			  		<Button color="primary">OK</Button>
	// 				</Link>
	// 			  </ModalFooter>
	// 		  </Modal>
	// 	)
	// }
}

export default Results