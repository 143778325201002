import {TMT_COMPLETE} from '../actions/ActionTypes'

export default function (state = [], action) {
	switch(action.type) {
		case TMT_COMPLETE:
			// TESTING REDUCER FUNCTION
			const thing = action.data
			console.log(thing)
			return thing
		default:
			return state;
	}
}