import React from 'react';
import './App.css'
import { Row } from 'reactstrap';
import { connect } from 'react-redux';
import SideNavBar from './SideNavBar'
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import routes from './routes'

class AppContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			testCount: 0
		}
	}
	
	componentDidMount() {	
		this.targetElement = document.querySelector('.ButtonStrip');
		//disableBodyScroll(this.targetElement) //lock/prevent page scrolling so that the "game board" doesn't move when accidentally dragging the screen during testing
		document.body.ontouchmove = (e) => { e.preventDefault(); return false; }
	} 
	  
	testCompleted = () => { this.setState({testCount: this.state.testCount+1}) }
  	
	render = () => {
	const { entryCode, testMode } = this.props    // Note: key prop for SideNavBar below is for waking it up after test completion
		return(
			<Row noGutters="true">
				<Router>
					<SideNavBar entryCode={entryCode} testMode={testMode} sideBarOpen={this.state.testFinished} key={this.state.testCount} />
					<Switch>
						{routes.test_routes.map((value, index) => {
							return(
								<Route path={value.path}>
									<div className="TestWindow">
									   {<value.component entryCode={entryCode} testDone={this.testCompleted} testMode={testMode}/> }
									</div>
								</Route>)
						})}
						{routes.practice_routes.map((value, index) => {
							return(
								<Route path={value.path}>
									<div className="TestWindow">
										{<value.component/>}
									</div>
								</Route>)
						})}
						<Route path={routes.results.path}>
							<div>
								{<routes.results.component/>}
							</div>
						</Route>
						<Route path={routes.senddata.path}>
							<div>
								{<routes.senddata.component entryCode={entryCode} testID={'all'}/>}
							</div>
						</Route>
						<Route path={routes.questionaire.path}>
							<div>
								{<routes.questionaire.component entryCode={entryCode}/>}
							</div>
						</Route>
						<Route path={routes.reset.path}>
							<div>
								{<routes.reset.component testMode={testMode}/>}
							</div>
						</Route>
					</Switch>
				</Router>
			</Row>
		)
	}
}

function mapStateToProps(state) {
	return {
		catsanddogs: state.catsanddogs,
		catsanddogsnomem: state.catsanddogsnomem,
		catsanddogsnomemfeb19: state.catsanddogsnomemfeb19,
		tmt: state.tmt,
		cpt: state.cpt,
		mem: state.mem,
	}
}

export default connect(mapStateToProps)(AppContainer);