import { combineReducers } from 'redux'
import CatsAndDogsReducer from './CatsAndDogsReducer'
import CatsAndDogsNoMemReducer from './CatsAndDogsNoMemReducer'
import CPTReducer from './CPTReducer'
import TMTReducer from './TMTReducer'
import MEMReducer from './MEMReducer'
import AutoReducer from './AutoReducer'

// Combine all reducers related to cat-tracker state
const webIBOCARootReducer = combineReducers({
	catsanddogs: CatsAndDogsReducer,
	catsanddogsnomem: CatsAndDogsNoMemReducer,
	cpt: CPTReducer,
	tmt: TMTReducer,
	mem: MEMReducer,
	auto: AutoReducer
})

export default webIBOCARootReducer;