import React from 'react';
import { Provider } from 'react-redux'
import store from './store'
import './App.css';
import { Button, Modal, ModalBody, ModalFooter, Input, Container } from 'reactstrap';
import AppContainer from './AppContainer';
import AutoAppContainer from './AutoAppContainer';
import {BrowserRouter, Route} from 'react-router-dom'

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			testingBegin: false,
			entryCode: null
		}
	}

	checkUrlSearchInput(){
		const params = new URLSearchParams(window.location.search);  //example: iboca3.com?code=qqqq&mode=demo
		const mode = params.get('mode')
		this.testMode = (mode == null) ? 'Normal' : (mode.toLowerCase() === 'demo') ? 'Demo' : 'Normal'    //case insensitive match to 'Demo'
		this.testMode = (mode == null) ? 'Normal' : (mode.toLowerCase() === 'cnu') ? 'CNU' : this.testMode //case insensitive match to 'CNU'
		const code = (this.testMode === 'CNU') ? ('CNU'+this.formattedDate()) : params.get('code')
		if (code !== null) {
			this.setState({ 
				entryCode: code,
				testingBegin: true 
			})
		}
	}

	formattedDate() {
		function fillzero (s) {return (s.length < 2) ? ('0' + s) : s}
		const d = new Date()
		const year = d.getFullYear().toString()
		const month = (d.getMonth() + 1).toString()
		const date = d.getDate().toString()
		const hour = d.getHours().toString()
		const minute = d.getMinutes().toString()		
		return '.' + year + '.' + fillzero(month) + fillzero(date) + '.' + fillzero(hour) + fillzero(minute) 
	}

	checkCode = () => {
		this.setState({ testingBegin: true })
	}

	inputChange = (e) => {
    	this.setState({ entryCode: e.target.value });
  	}

	render() {	
		const { testingBegin, entryCode } = this.state
		return(
			<BrowserRouter>
			<Provider store={store}>
				<Container fluid="true">
					{!testingBegin && this.checkUrlSearchInput()}
					{!testingBegin && <Modal centered={true} isOpen={true}>
	                     <ModalBody className="text-center">
	                     	<p className="my-2">Please enter your entry code</p>
	                     	<Input placeholder="*****"
	                     		className="text-center"
	                     		value={entryCode}
	                     		onChange={this.inputChange}/>
	                     </ModalBody>
	                     <ModalFooter className="d-flex justify-content-center">
	                         <Button disabled={entryCode === null || entryCode === ""} color="primary" onClick={this.checkCode}>Enter</Button>
	                     </ModalFooter>
	                 </Modal>}
	                 <Route exact path="/">
	                	{testingBegin && <AppContainer entryCode={entryCode} testMode={this.testMode} />}
	                </Route>
	                <Route exact path="/auto">
	                	{testingBegin && <AutoAppContainer entryCode={entryCode} testMode={"auto"} />}
	                </Route>
				</Container>
			</Provider>
			</BrowserRouter>
		)
	}
}

export default App;
