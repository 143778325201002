import React from 'react';
import imgArray from './images.js'
import sndDone from '../audio/coinflip190m.wav';
import sndCorrect from '../audio/ding240m.wav';
import sndIncorrect from '../audio/incorrect100m.wav';
import voiceInstruction from '../audio/instructions1_memtest1_allison_low.mp3';
import '../../../App.css';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { memComplete } from '../../../actions/MEMComplete';
//import { buildQueries } from '@testing-library/react';
import SendData from '../../../SendData';
import PatientQuestionnaire from '../../../PatientQuestionnaire'

class MEM extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showInstructions: true,
			showSettings: true,
			answerBoxes: [],
			selectionBoxes: [],
			stage: 'presentation',
			testCompleted: false,
			instructAudioFinished: false,
			selectionGridInstruction: '',
			answerGridInstruction: ''
		}
	}
  
	debugMode = false   // USED FOR TESTING ONLY
	// The data that eventually gets sent to AWS
	data = [{ ver: 'GridMem JS v1.05 (4/21/21)', date: Date() }]  //First element of the data array has task version info (title, version, revdate) and test date/time
	selection = []
	placement = []
	trialScoring = []
	itemNum = 0
	trialNum = 0

	// Defaults for test settings/options Box
	numAnswers = 3           				// this is the default value only, gets set in the Settings page
	numItemsPerCat = 3          			// this is the default value only, gets set in the Settings page
	answerGridN = 9          				// this is the default value only, gets set in the Settings page
	encodingTrialsN = 3          			// this is the default value only, gets set in the Settings page
	timingInfo = { retentionTime: 60000,	      	// this is the default value only, gets set in the Settings page
					postPresentationDelay: 2000,   	// this is the default value only, gets set in the Settings page
					presentationSpacing: 700, 		// this is the default value only, gets set in the Settings page
					preTrialDelay: 2000, 	       	// this and the following values are 'permanent' settings
					lastAnswerPersistence: 2000,
					selectionGridTimeLimit: 10000, 
					answerGridTimeLimit: 10000
					}
	recallTrialsN = 1   //the number of test trials(without an animation for encoding)
	instructTime = 5000   // Voice instructions are a litle over 5sec long (so start button enabled after 5sec)

	dat = this.datInit() 
	previousClick = { index: -1, source: 0 }
	numberAdjectives = ['1st','2nd','3rd','4th','5th','6th','7th','8th','9th','10th']
	instructAudioStarted = false
	trialStartTime = null        
	testStartTime = Date.now()      
	myAudio = this.setupWebAudio()

	
	makeGrid(N, hasOffset, otherGridN) {  // computes the grid coordinates (used for both grids: presentation (left) & selection(right))
		const grid = {...this.basicGridLayout}
		grid.N = N
		grid.rows    = (N===9) ? 3 : (N===12) ? 4 : (N===15 || N===16) ? 4: 5
		grid.columns = (N===9) ? 3 : (N===12) ? 3 : (N===15 || N===16) ? 4: 4
		const otherGridcolumns = (otherGridN===9) ? 3 : (otherGridN===12) ? 3 : 4
		grid.spacing = (1000 - grid.intergridSpacing  - 2*grid.x0) / (grid.columns + otherGridcolumns)
		grid.x0 += (!hasOffset) ? 0 : grid.spacing*otherGridcolumns + grid.intergridSpacing 
		grid.coordinates = []
		let yGrid = 0
		let xGrid = -1
		for (let i = 0; i < N; i++) {
			yGrid += (xGrid < grid.columns-1) ? 0 : 1
			xGrid += (xGrid < grid.columns-1) ? 1 : -xGrid
			grid.coordinates[i] = 
				{x: grid.x0 + xGrid * grid.spacing, 
				y: grid.y0 + yGrid * grid.spacing,
			}
		}
		return grid
	}
	
	selectItemsFromLibrary() {  // select photos to be used in the memory sequence
		const { imgLibrary } = this
		const items  = { targets: [], selectionGrid: [], answerGrid: [], library: [] }
		let k = -1
		for (let i = 0; i < imgLibrary.length; i++) { 
			items.library[i] = []
			for (let j = 0; j < imgLibrary[i].length; j++) { 
				k++
				items.library[i][j] = {ID: k, imgName: this.imgNames[i][j] }    //consider removing the img field from this, and then relying on imgLibrary below
			}
		}
		let categoryList = []
		let categories = []
		let exampleList = []
		let examples = []
		let iList = []
		let item = null
		for (let i = 0; i < k; i++) { iList[i]=i }     // generate a whole-number array big enough that category & example indices can be sliced from it
		categoryList = iList.slice(0,items.library.length)                                 			// list of all category indices
		categories = categoryList.sort(() => Math.random() - 0.5).slice(0,this.numAnswers)          // select a subset [numCats] of the avaialbale categories
		for (let i = 0; i < categories.length; i++) {
			exampleList = iList.slice(0,items.library[categories[i]].length)              			// list of all example indices for the current sategory
			examples = exampleList.sort(() => Math.random() - 0.5).slice(0,this.numItemsPerCat)     // select a subset [numItemsPerCat] of the avaialbale examples
			item = items.library[categories[i]][examples[0]]
			items.targets.push(item)                       											// choose 1 of the selected examples as the target
			items.answerGrid.push(item)	  								
			for (let j = 0; j < examples.length; j++) {
				item = items.library[categories[i]][examples[j]]
				items.selectionGrid.push(item)							// put all [numExamples] selected examples into the display list
			}
		}
		for (let i = 0; i < this.answerGridN - this.numAnswers; i++) { items.answerGrid.push({ ID: null, img: null }) }  // pad answerGrid up to this.answerGridN elements
		items.answerGrid.sort(() => Math.random() - 0.5) 												// shuffle answerGrid to randomize ordering for targets
		items.selectionGrid.sort(() => Math.random() - 0.5) 											// shuffle selectionGrid to randomize ordering for cats & examples
		
		for (let i = 0; i < items.targets.length; i++) {
			for (let j = 0; j < items.selectionGrid.length; j++) {
				if (items.selectionGrid[j].ID === items.targets[i].ID) {
					items.targets[i].selectionBox = j
				}
			}
			for (let j = 0; j < items.answerGrid.length; j++) {
				if (items.answerGrid[j].ID === items.targets[i].ID) {
					items.targets[i].answerBox = j
				}
			}
		}
		console.log('tgts')
		console.log(items.targets)
		console.log('sGrid')
		console.log(items.selectionGrid)    // fields: {ID, img, loation}
		console.log('aGrid')
		console.log(items.answerGrid)  
		console.log('lib')
		console.log(items.library)
		return items
	}

	applySettings() {  // Setup based on assignments from "Settings" page:  numAnswers, numItemsPerCat, answerGridN, encodingTrialsN retentionTime
		const {numAnswers, numItemsPerCat, answerGridN, encodingTrialsN, retentionTime} = this
		this.selectionGridN = numAnswers*numItemsPerCat  // each answer will come for a diff category, so num categories = num answers 
		this.basicGridLayout = {x0: 10, y0: 60, intergridSpacing: 80}  // neded for makeGrid function below
		this.answerGrid = this.makeGrid (answerGridN, 0, this.selectionGridN)
		this.selectionGrid = this.makeGrid (this.selectionGridN, 1, answerGridN)
		this.answerGridBottom = this.answerGrid.coordinates[this.answerGrid.coordinates.length-1].y + this.answerGrid.spacing
		this.selectionGridBottom = this.selectionGrid.coordinates[this.selectionGrid.coordinates.length-1].y + this.selectionGrid.spacing
		this.imgLibrary = imgArray                                    // note that imgArray is imported from image.js above
		this.imgCategories = ['Animals', 'Flowers', 'Garden', 'Kitchen', 'Musical instrumnents', 'Mythical', 'Tools', 'Vegetables'] //currently unused
		this.imgNames = [['koala', 'ostrich', 'panda', 'walrus'], 
						['daisy', 'lily', 'sunflower', 'tulip'],
						['pickaxe', 'pitchfork', 'trowel', 'wheelbarrow'],
						['corkscrew', 'funnel', 'ladle', 'tongs'],
						['harmonica', 'tambourine', 'tuba', 'xylophone'],
						['centaur', 'pegasus', 'sphinx', 'unicorn'],
						['chisel', 'level', 'plane', 'pliers'],
						['artichoke', 'brusselsprout', 'eggplant', 'okra']]
		this.items = this.selectItemsFromLibrary()  // get randomly selected items.targets & items.selectionGrid
		let k = -1
		this.img = []
		for (let i = 0; i < this.items.library.length; i++) {
			for (let j = 0; j < this.items.library[i].length; j++) {
				k++
				this.img[k] = new Image();
				this.img[k].src = this.imgLibrary[i][j]   // by setting the src, we hopefully trigger browser download to preload the images
			}
		}
	}

	// RESET selectionBoxes & answerBoxes (i.e. the grid contents) BACK TO THEIR ORIGINAL STATES
	initializeBoxes() {
		const { items, selectionGrid, answerGrid  } = this
		const { answerBoxes, selectionBoxes } = this.state
		for (let i = 0; i < selectionGrid.N; i++) {
			selectionBoxes[i] = { 	state: 'unused', 
									item: items.selectionGrid[i].ID,
									img: items.selectionGrid[i].imgName,
									x: selectionGrid.coordinates[i].x, 
									y: selectionGrid.coordinates[i].y 
									}
		}
		for (let i = 0; i < answerGrid.N; i++) {
			answerBoxes[i] = { 	state: 'unused', 
								item: null, 
								x: answerGrid.coordinates[i].x, 
								y: answerGrid.coordinates[i].y 
								}
		}
		this.setState({ selectionBoxes: selectionBoxes, answerBoxes: answerBoxes })
	}

	// RESET selectionBoxes & answerBoxes BACK TO THEIR ORIGINAL STATES
	clearBoxes() {
		const { items, selectionGrid, answerGrid  } = this
		const { answerBoxes, selectionBoxes } = this.state

		for (let i = 0; i < selectionGrid.N; i++){
			selectionBoxes[i].state = 'unused'
			selectionBoxes[i].item = items.selectionGrid[i].ID
		}
		for (let i = 0; i < answerGrid.N; i++){
			answerBoxes[i].state = 'unused'
			answerBoxes[i].item = null
		}
		this.setState({ selectionBoxes: selectionBoxes, answerBoxes: answerBoxes })
	}

	componentDidMount() { // currently unused
	} 

	datInit() {  // initialize task data variables
		return {nCorrect: 0, nIncorrect: 0, tInstruct: 0, tPerform: 0}
	}

	// SETUP for using Web Audio API: defining an AudioContext, and loading a buffer with decoded raw sound data
	setupWebAudio() {
		const sndList = [sndCorrect, sndIncorrect, sndDone] //, instruct1, instruct3] 
		const myAudio = {context: null, buffer: [], bufferLoaded: [], iCorrect: 0, iIncorrect: 1, iEndRound: 2, iInstructA: 3, iInstructB: 4 }	
		window.AudioContext = window.AudioContext||window.webkitAudioContext;
		myAudio.context = new AudioContext();//myAudio.context = new AudioContext(window.AudioContext || window.webkitAudioContext);
		for (let i = 0; i < sndList.length; i++) {
			myAudio.bufferLoaded[i]=false                   //initialization allowing us to check beffer length later on
			const request = new XMLHttpRequest();
			request.open('GET', sndList[i], true);
			request.responseType = 'arraybuffer';
			request.onload = function() {
				myAudio.context.decodeAudioData(request.response, function(theBuffer) {        // Decode asynchronously
				myAudio.buffer[i] = theBuffer;
				myAudio.bufferLoaded[i]=true                   //initialization allowing us to check beffer length later on
	  			}, e => console.log('sound load message: [' + i + '] ' + e) );
			}
			request.send();
		}
		return myAudio
	}

	playWebAudio(bufferIndex) {   // play some audio using the Web Audio API (low latency)
		const source = this.myAudio.context.createBufferSource()
		source.buffer = this.myAudio.buffer[bufferIndex]
		source.connect(this.myAudio.context.destination)
		source.start(0)
	}

	playSound(snd) {     // play some audio using the basic html audio (poor latency, but more efficient for prolonged audio from mp3s for intructions)
		const correctAudio = new Audio(snd)
		correctAudio.play()
	}

	// Performs a 2-level clone for an array of objects (objects fields should be individual values)
	cloneArray(x) {    //helper function
		let xClone=[]
		for (let i = 0; i < x.length; i++) xClone[i]={...x[i]} 
		return xClone
	}
	
	// USED TO SHOW INSTRUCTIONS BEFORE THE DIFFERENT STAGES OF TESTING
	toggleInstructions = () => {
		this.instructAudioStarted = false
		this.setState({ instructAudioFinished: false, showInstructions: false })
		this.trialStart()
	}

	// CALLED AT THE BEGINNING OF EACH TRIAL
	trialStart() {   // if encoding trial: play sequence   if retention trial: start countdown timer
		const { preTrialDelay,  presentationSpacing, retentionTime } = this.timingInfo
		this.itemNum = 0
		this.playWebAudio(this.myAudio.iEndRound)
		this.dat = this.datInit()    // initialize summary data
		this.dat.tInstruct = (Date.now() - this.instructStartTime)/1000
		this.trialStartTime = Date.now()
		if (this.trialNum===0) {
			this.instructAudioStarted = false
			this.setState({ instructAudioFinished: false, showInstructions: false, showSettings: false })
			this.initializeBoxes()
		} else {
			this.clearBoxes()
		}
		const isEncodingTrial = (this.trialNum < this.encodingTrialsN)
		if (isEncodingTrial) {    // start the item-presentation animation after a delay of 'pretrialdelay'
			this.setState({ answerGridInstruction: 'Learn the sequence'  })
			setTimeout(() =>  {	
				this.setState({ stage: 'presentation' }); 
				this.presentItems(0)
			}, preTrialDelay) 
		} else {                 // if isEncodingTrial = false, go directly to the 'selection' stage, but after a delay of 'retentionTime'
			setTimeout(() =>  { 
				this.setState({ stage: 'selection', selectionGridInstruction: 'Tap the 1st picture', answerGridInstruction: '', countdownValue: 0 }) 
			}, retentionTime) 
			for (let i = retentionTime; i > 0; i-=1000) {     // display a countdown timer that updates every 1sec (=1000ms)
				setTimeout(() => { 
					this.setState({ countdownValue: this.round(i/1000,0)})
				}, retentionTime - i)
			}		
		}		
	}
   
	// SHOW THE TARGET SEQUENCE ANIMATION
	presentItems(i) {
		const { presentationSpacing, postPresentationDelay, selectionGridTimeLimit } = this.timingInfo
		const { answerBoxes } = this.state
		const { myAudio, items } = this
		if (i===0) {  // show 1st item (i = 0) in animation immediately!
			answerBoxes[items.targets[i].answerBox].item = items.targets[i].ID
			answerBoxes[items.targets[i].answerBox].state = 'spotlight'
			this.playWebAudio(myAudio.iCorrect)
			this.setState({ answerBoxes: answerBoxes })
			this.presentItems(++i)              
		} else {    // show items 2:N (i = 1:N-1) after a delay!
			setTimeout(() => {
				answerBoxes[items.targets[i].answerBox].item = items.targets[i].ID
				answerBoxes[items.targets[i].answerBox].state = 'spotlight'
				answerBoxes[items.targets[i-1].answerBox].state = 'used'
				this.playWebAudio(myAudio.iCorrect)
				this.setState({ answerBoxes: answerBoxes })               
				if (++i < this.numAnswers) {
					this.presentItems(i);   //  decrement i and call myLoop again if i < numAnswers
				} else { setTimeout(() => {
					this.selectionStartTime = Date.now()
					this.clearBoxes()
					this.setState({ stage: 'selection', selectionGridInstruction: 'Tap the 1st picture', answerGridInstruction: ''  })
					setTimeout((itemN, trialN) => this.processLateSelection(itemN, trialN), selectionGridTimeLimit, this.itemNum, this.trialNum)
					}, postPresentationDelay)
				}
			}, presentationSpacing)
		}
	}	
	
	processLateSelection(itemN, trialN){
		if (this.itemNum===itemN && this.trialNum===trialN && this.state.stage==='selection') {
			this.playWebAudio(this.myAudio.iIncorrect)
			this.selection[this.itemNum] = { time: this.round((Date.now()-this.selectionStartTime)/1000, 2), location: null, item: null, img: null, xc: null, yc: null, correct: null }
			this.selectionGridAssign()
		}
	}

	processLateAnswer(itemN, trialN){
		if (this.itemNum===itemN && this.trialNum===trialN && this.state.stage==='placement') {
			this.playWebAudio(this.myAudio.iIncorrect)
			this.placement[this.itemNum] = { time: this.round((Date.now()-this.placementStartTime)/1000, 2), location: null, xc: null, yc: null, correct: null  }
			this.answerGridAssign()
		}
	}

	// PROCESS A SELECTION GRID CLICK AND CORRECT IT IF NEED BE
	selectionGridClick(value, index, source, e) {
		const { selectionBoxes, stage } = this.state
		const { myAudio, items, selection } = this
		const i = this.itemNum
		const correctIndex = items.targets[i].selectionBox
		console.log(['tgt',index, 'source',source, 'time', Date.now() - this.trialStartTime])
		if ( selectionBoxes[index].state === 'unused' && stage === 'selection')  {  //reject clicks before test is ready for them OR if item is already clicked
			selection[i] = { time: this.round((Date.now()-this.selectionStartTime)/1000, 2),    // selection info to be saved in trialEnd()
							location: index, 
							item: selectionBoxes[index].item,
							img: items.selectionGrid[index].imgName,
							xc: e.nativeEvent.offsetX, 
							yc: e.nativeEvent.offsetY
							}
			if (index === correctIndex) {
				selection[i].correct = true
				this.playWebAudio(myAudio.iCorrect)
			} else {
				selection[i].correct = false
				this.playWebAudio(myAudio.iIncorrect)
			}
			this.selectionGridAssign()	
		}
	}

	selectionGridAssign() {
		const { answerGridTimeLimit } = this.timingInfo
		const { selectionBoxes } = this.state
		const { items, selection } = this
		const i = this.itemNum
		const correctIndex = items.targets[i].selectionBox
		selectionBoxes[correctIndex].state = 'spotlight'
		this.placementStartTime = Date.now()
		const adjective = this.numberAdjectives[i]
		this.setState({ selectionBoxes: selectionBoxes, stage: 'placement', answerGridInstruction: ('Place the ' + adjective + ' picture'), selectionGridInstruction: ''  })
		setTimeout((itemN, trialN) => this.processLateAnswer(itemN, trialN), answerGridTimeLimit, this.itemNum, this.trialNum)
		}

	// PROCESS AN ANSWER GRID CLICK AND CORRECT IT IF NEED BE
	answerGridClick(value, index, source, e) {
		const { answerBoxes, selectionBoxes, stage } = this.state
		const { myAudio, items, selection, placement } = this
		const i = this.itemNum
		const correctAnswerIndex = items.targets[i].answerBox    //BUG CHECK: make sure items.targets[answerBox.length-1] exits!!!  currently to short i  think
		console.log(['tgt',index, 'source',source, 'time', Date.now() - this.trialStartTime])
		if ( answerBoxes[index].state==='unused' && stage === 'placement' ) {  //reject clicks before test is ready for them OR if item is already clicked
			placement[i] = { time: this.round((Date.now()-this.placementStartTime)/1000, 2), 	//item placement info to be saved in trialEnd()
							location: index,
							xc: e.nativeEvent.offsetX, 
							yc: e.nativeEvent.offsetY
							}
			if (index === correctAnswerIndex) {
				placement[i].correct = true
				this.playWebAudio(myAudio.iCorrect)
			} else {
				placement[i].correct = false
				this.playWebAudio(myAudio.iIncorrect)
			}
			this.answerGridAssign() 
		}
	}


	answerGridAssign() {
		const { selectionGridTimeLimit, lastAnswerPersistence } = this.timingInfo
		const { answerBoxes, selectionBoxes } = this.state
		const { items, placement } = this
		const i = this.itemNum
		const correctAnswerIndex = items.targets[i].answerBox
		const correctSelectionIndex = items.targets[i].selectionBox
		answerBoxes[correctAnswerIndex].item = selectionBoxes[correctSelectionIndex].item	
		answerBoxes[correctAnswerIndex].state = 'spotlight'
		selectionBoxes[correctSelectionIndex].state = 'used'
		if (i > 0) { answerBoxes[items.targets[i-1].answerBox].state = 'used' }
		this.selectionStartTime = Date.now()
		this.setState({ selectionBoxes: selectionBoxes, answerBoxes: answerBoxes})
		if (++this.itemNum < this.numAnswers) { 
			const adjective = this.numberAdjectives[this.itemNum]
			this.setState({ stage: 'selection', selectionGridInstruction: 'Tap the ' + adjective + ' picture', answerGridInstruction: ''  })
			setTimeout((itemN, trialN) => this.processLateSelection(itemN, trialN), selectionGridTimeLimit, this.itemNum, this.trialNum)
		} else { 
			setTimeout(() =>  { this.setState({ stage: 'delay', answerGridInstruction: '' }); this.trialEnd() }, lastAnswerPersistence)	
		}
	}
	
			// CALLED AT THE END OF EACH ROUND - rename to roundEnd??
	trialEnd() {
		const { selectionBoxes } = this.state
		const { selection, placement, trialNum, items, trialScoring } = this


		if (trialNum === 0) {  // info added to the datastream (DS), but only onces & at the beginning of it
			this.data.push({ targetSequence: this.cloneArray(items.targets) })  // Add the target sequence to the data stream
			this.data.push({ selectionGrid: this.cloneArray(selectionBoxes) })  // Add the box conformation to the data stream
		}
		this.data.push({ selectionData: this.cloneArray(selection) })    // add item selection (what) on each trial to the DS
		this.data.push({ placementData: this.cloneArray(placement) })    // add item placement (where) on each trial to the DS
		trialScoring[trialNum] = this.computeTrialSummary()        // Calculate performance summary information for each trial
		if (++this.trialNum < (this.encodingTrialsN + this.recallTrialsN)) { 
			this.trialStart()        // proceed to next trial
		} else {
			const x = this.computeTestSummary(trialScoring)             // compute summary info for the test 
			this.data.push({recall_TotalWhatWhere: x.recallSummary,     // add this summary info to the data stream (DS)  
							lastEncodingTrial: x.encodingSummaryLast,
							encodingTrialAvg: x.encodingSummary,
							duration: this.round((Date.now() - this.instructStartTime)/1000, 1), 
							retentionTime: this.round(this.timingInfo.retentionTime/1000, 0),
							numItems: this.numAnswers,
							numEncodingTrials: this.encodingTrialsN,
							encodingTimeOuts: x.encodingSummaryTO,
							recallTimeOuts: x.recallSummaryTO,
						})
			this.setState({ stage: 'completed', testCompleted: true })
		}
	}	

	computeTrialSummary() {      // Calculate performance summary information for each trial
		const { selection, placement } = this
		let selectionCorrect = 0
		let placementCorrect = 0
		let selectionTimeout = 0
		let placementTimeout = 0
		for (let i = 0; i < this.numAnswers; i++) { 
			selectionCorrect += (selection[i].correct === true)
			placementCorrect += (placement[i].correct === true)
			selectionTimeout += (selection[i].correct == null)
			placementTimeout += (placement[i].correct == null)
		}	

		return {selectionCorrect: selectionCorrect,
				placementCorrect: placementCorrect,
				selectionTimeout: selectionTimeout,
				placementTimeout: placementTimeout,
				}
	}

	computeTestSummary(trialScoring) {   // Compute summary info for the test 
		const x = trialScoring
		let encodingSelection = 0
		let encodingPlacement = 0
		let encodingSelectionTO = 0
		let encodingPlacementTO = 0
		for (let i = 0; i < this.encodingTrialsN; i++) {
			encodingSelection += 100*x[i].selectionCorrect/this.encodingTrialsN/this.numAnswers
			encodingPlacement += 100*x[i].placementCorrect/this.encodingTrialsN/this.numAnswers
			encodingSelectionTO += x[i].selectionTimeout
			encodingPlacementTO += x[i].placementTimeout
		}
		const encodingTotal = (encodingSelection + encodingPlacement) / 2
		const encodingTotalTO = (encodingSelectionTO + encodingPlacementTO) 
		const encodingSummary = [this.round(encodingTotal,1), this.round(encodingSelection,1), this.round(encodingPlacement,1)]
		const encodingSummaryTO = [this.round(encodingTotalTO,1), this.round(encodingSelectionTO,1), this.round(encodingPlacementTO,1)]
		
		const k = this.encodingTrialsN - 1
		const encodingSelectionLast = 100*x[k].selectionCorrect/this.numAnswers
		const encodingPlacementLast = 100*x[k].placementCorrect/this.numAnswers
		const encodingTotalLast = (encodingSelectionLast + encodingPlacementLast) / 2
		const encodingSummaryLast = [this.round(encodingTotalLast,1), this.round(encodingSelectionLast,1), this.round(encodingPlacementLast,1)]
		
		let recallSelection = 0
		let recallPlacement = 0
		let recallSelectionTO = 0
		let recallPlacementTO = 0
		let j = 0
		for (let i = 0; i < this.recallTrialsN; i++) {
			j = i + this.encodingTrialsN
			recallSelection += 100*x[j].selectionCorrect/this.recallTrialsN/this.numAnswers
			recallPlacement += 100*x[j].placementCorrect/this.recallTrialsN/this.numAnswers
			recallSelectionTO += x[j].selectionTimeout
			recallPlacementTO += x[j].placementTimeout
		}
		const recallTotal = (recallSelection + recallPlacement) / 2
		const recallTotalTO = (recallSelectionTO + recallPlacementTO)
		const recallSummary = [this.round(recallTotal,1), this.round(recallSelection,1), this.round(recallPlacement,1)]
		const recallSummaryTO = [this.round(recallTotalTO,1), this.round(recallSelectionTO,1), this.round(recallPlacementTO,1)]
		return {encodingSummary: encodingSummary, 
				encodingSummaryLast: encodingSummaryLast,
				encodingSummaryTO: encodingSummaryTO, 
				recallSummary: recallSummary, 
				recallSummaryTO: recallSummaryTO, 	
				}
	}


	toggle = () => {
        this.props.memComplete(this.data)
	}

	speakInstructions = () => {
		const { instructTime } = this
		if (!this.instructAudioStarted) {
			this.playSound(voiceInstruction)
			this.instructStartTime = Date.now()
			this.instructAudioStarted = true  // Note: cannot use a state var with setstate() for this, as it wouldn't update immediately!!
			this.setState({showInstructions: this.state.showInstructions})  // Hack to trigger render() (even though showInstructions unchanged)
			//let ticks = (currentStage === 'A1') ? instructTicksA1 : (currentStage === 'A2') ? instructTicksA2 : instructTicksB
			setTimeout(() => this.setState({ instructAudioFinished: true }),  instructTime) // TIMER THAT RUNS TO ENABLE START BUTTON AFTER VOICE INSTRUCTIONS FINISHED
		}
	}
	
	radioEvent(e, radioNum) {  // used for the radio buttons on the parameter selection page before starting the test
		if (radioNum === 0) this.numAnswers = parseInt(e.target.value)
		if (radioNum === 1) this.numItemsPerCat = parseInt(e.target.value) + 1
		if (radioNum === 2) this.answerGridN = parseInt(e.target.value)
		if (radioNum === 3) this.encodingTrialsN = parseInt(e.target.value)
		if (radioNum === 4) this.timingInfo.retentionTime = parseInt(e.target.value)
		if (radioNum === 5) this.timingInfo.presentationSpacing = parseInt(e.target.value)
		if (radioNum === 6) this.timingInfo.postPresentationDelay = parseInt(e.target.value)	
			
		this.setState({  }) 
		this.applySettings()
	} 

	renderSettingsModal() {  // used for the radio buttons on the parameter selection page before starting the test
		return (
			<Modal centered={true} isOpen={true} size='lg'>
				<ModalHeader className="d-flex justify-content-center">
					Settings
				</ModalHeader>
				<ModalBody>
					<div onChange={(e) => this.radioEvent(e,0)}>
						Target sequence Length: 
        				<input type="radio" value="3" checked={this.numAnswers===3} style={{margin: '12px 0px 12px 20px'}}/> 3 
        				<input type="radio" value="4" checked={this.numAnswers===4} style={{margin: '12px 0px 12px 20px'}}/> 4 
						<input type="radio" value="5" checked={this.numAnswers===5} style={{margin: '12px 0px 12px 20px'}}/> 5 
     				</div>
					 <div onChange={(e) => this.radioEvent(e,1)}>
						 Foils per target item:
        				<input type="radio" value="2" checked={this.numItemsPerCat===3} style={{margin: '12px 0px 12px 20px'}}/> 2 
        				<input type="radio" value="3" checked={this.numItemsPerCat===4} style={{margin: '12px 0px 12px 20px'}}/> 3 
						. . . <b>({this.numAnswers*this.numItemsPerCat} item selection grid)</b>
     				</div>
					 <div onChange={(e) => this.radioEvent(e,2)}>
						 Answer grid size: 
        				<input type="radio" value="9" checked={this.answerGridN===9} style={{margin: '12px 0px 12px 20px'}}/> 3x3 
        				<input type="radio" value="12" checked={this.answerGridN===12} style={{margin: '12px 0px 12px 20px'}}/> 4x3 
						<input type="radio" value="16" checked={this.answerGridN===16} style={{margin: '12px 0px 12px 20px'}}/> 4x4 
     				</div>
					 <div onChange={(e) => this.radioEvent(e,3)}>
						 Encoding Trials:  
					 	<input type="radio" value="2" checked={this.encodingTrialsN===2} style={{margin: '12px 0px 12px 20px'}}/> 2 
        				<input type="radio" value="3" checked={this.encodingTrialsN===3} style={{margin: '12px 0px 12px 20px'}}/> 3 
						<input type="radio" value="4" checked={this.encodingTrialsN===4} style={{margin: '12px 0px 12px 20px'}}/> 4 
						<input type="radio" value="5" checked={this.encodingTrialsN===5} style={{margin: '12px 0px 12px 20px'}}/> 5
     				</div><div onChange={(e) => this.radioEvent(e,4)}>
						 Retention interval:  
					 	<input type="radio" value="5000" checked={this.timingInfo.retentionTime===5000} style={{margin: '12px 0px 12px 20px'}}/> 5 sec 
						<input type="radio" value="30000" checked={this.timingInfo.retentionTime===30000} style={{margin: '12px 0px 12px 20px'}}/> 30 sec 
        				<input type="radio" value="60000" checked={this.timingInfo.retentionTime===60000}style={{margin: '12px 0px 12px 20px'}}/> 1 min 
						<input type="radio" value="120000" checked={this.timingInfo.retentionTime===120000} style={{margin: '12px 0px 12px 20px'}}/> 2 min 
						<input type="radio" value="180000" checked={this.timingInfo.retentionTime===180000} style={{margin: '12px 0px 12px 20px'}}/> 3 min 
     				</div><div onChange={(e) => this.radioEvent(e,5)}>
						 Animation Interval:  
					 	<input type="radio" value="500" checked={this.timingInfo.presentationSpacing===500} style={{margin: '12px 0px 12px 20px'}}/> 0.5 sec 
						<input type="radio" value="700" checked={this.timingInfo.presentationSpacing===700} style={{margin: '12px 0px 12px 20px'}}/> 0.7 sec 
						<input type="radio" value="1000" checked={this.timingInfo.presentationSpacing===1000} style={{margin: '12px 0px 12px 20px'}}/> 1 sec 
						<input type="radio" value="2000" checked={this.timingInfo.presentationSpacing===2000} style={{margin: '12px 0px 12px 20px'}}/> 2 sec 
        				<input type="radio" value="3000" checked={this.timingInfo.presentationSpacing===3000}style={{margin: '12px 0px 12px 20px'}}/> 3 sec 
     				</div><div onChange={(e) => this.radioEvent(e,6)}>
						 Post-animation persistence:  
					 	<input type="radio" value="1000" checked={this.timingInfo.postPresentationDelay===1000} style={{margin: '12px 0px 12px 20px'}}/> 1 sec 
						<input type="radio" value="2000" checked={this.timingInfo.postPresentationDelay===2000} style={{margin: '12px 0px 12px 20px'}}/> 2 sec 
						<input type="radio" value="3000" checked={this.timingInfo.postPresentationDelay===3000} style={{margin: '12px 0px 12px 20px'}}/> 3 sec 
     				</div>
				</ModalBody>
				<ModalFooter className="d-flex justify-content-center">
				<Button disabled={false} color="primary" onClick={() => {this.applySettings(); this.setState({ showSettings: false })}}>OK</Button>
				</ModalFooter>
			</Modal>)
	}

	renderInstructions(stage) {  // present text & audio instructions - only "A1" currently used, should clean-up the other stages!
		const { instructAudioStarted } = this
		const { instructAudioFinished } = this.state
		if (stage === 'A1') {
			this.speakInstructions()
			return (
				<Modal centered={true} isOpen={true}>
					<ModalBody className="text-center">You will see a short sequence of pictures appear in the gray boxes. Please remember the pictures and their
													   locations in order.
					</ModalBody>
					<ModalFooter className="d-flex justify-content-center">
					<Button disabled={!instructAudioFinished} color="primary" onClick={() => this.trialStart()}>Start</Button>
					</ModalFooter>
				</Modal>)
		} if (stage === 'A2' && instructAudioStarted) {
			return (
				<Modal centered={true} isOpen={true}>
					<ModalBody className="text-center">Now there will be many more numbers on the screeen. Please touch them in increasing order again,
													   working as quickly and accurately as you can.
					</ModalBody>
					<ModalFooter className="d-flex justify-content-center">
					<Button disabled={!instructAudioFinished} color="primary" onClick={this.trialStart}>Start</Button>
					</ModalFooter>
				</Modal>)
		} else if (stage === 'B' && instructAudioStarted) {
			return (
				<Modal centered={true} isOpen={true}>
					<ModalBody className="text-center">Now there will be numbers and letters in circles on the screen. Please touch them in alternating order. 
													   Start at number <b>1</b>, then go to the first letter, <b>A</b>, then go to the next number, <b>2</b>, 
													   and then the next letter, <b>B</b>, and so on. Work as quickly and accurately as you can.
					</ModalBody>
					<ModalFooter className="d-flex justify-content-center">
					<Button disabled={!instructAudioFinished} color="primary" onClick={this.trialStart}>Start</Button>
					</ModalFooter>
				</Modal>)
		} else if ( (!instructAudioStarted) && (stage === 'A2' || stage === 'B') ) {  // Popup directly linked to instruction voice over so iOS deosn't suppress it!!!
			const partNumber = (stage === 'A2') ? '1':'2'
			//const instructAudioStarted = (stage === 'A2') ? this.instructA2AudioStarted:this.instructBAudioStarted
			return (
				<Modal centered={true} isOpen={true}>
					<ModalBody className="text-center">Part {partNumber} Complete!</ModalBody>
					<ModalFooter className="d-flex justify-content-center">
					<Button color="primary" onClick={this.speakInstructions}>Proceed</Button>
					</ModalFooter>
				</Modal>)
		}
	}

	renderSelectionBox(value, index) {  //render the photos in the grids
		const { stage } = this.state
		const boxOpacity = (value.state==='used') ? 0.2 : ((value.state==='unused') && (stage==='placement')) ? 0.2 : 1     // if photo has been used (or stage === 'placemennt'), make it partially transparent to make it dull-looking de-emphasize it
		const borderWidth = (value.state==='spotlight') ? '3px' : '1px'
		const borderColor = (value.state==='spotlight') ? 'blue' : 'silver'
		const imgSize = (this.answerGrid.spacing - 10) + 'px'
		return(
			<img src={this.img[value.item].src} style={{
					position: 'absolute',
					width: imgSize,
					height: imgSize,
					top: value.y,
					left: value.x,
					opacity: boxOpacity,
					borderColor: borderColor,
					borderStyle: 'solid',
					borderWidth: borderWidth
				}}
				onTouchStart={(e) => this.selectionGridClick(value, index, 1, e)}
				onMouseDown={(e) => this.selectionGridClick(value, index, 2, e)}
			/> 
		)
	}

	renderAnswerBox(value, index) {
		const borderWidth = (value.state==='spotlight') ? '3px' : '1px'
		const borderColor = (value.state==='spotlight') ? 'blue' : 'silver'
		const imgSize = (this.answerGrid.spacing - 10) + 'px'
		const imgsrc = (value.item==null) ? null : this.img[value.item].src
		if (value.item==null)
		return(
			<div style={{
					position: 'absolute',
					width: imgSize,
					height: imgSize,
					top: value.y,
					left: value.x,
					backgroundColor: 'silver', 
					borderColor: borderColor,
					borderStyle: 'solid',
					borderWidth: borderWidth
				}}
				onTouchStart={(e) => this.answerGridClick(value, index, 1, e)}
				onMouseDown={(e) => this.answerGridClick(value, index, 2, e)}
			/>
		)
		else
		return(
			<img src={imgsrc} style={{
					position: 'absolute',
					width: imgSize,
					height: imgSize,
					top: value.y,
					left: value.x,
					borderColor: borderColor,
					borderStyle: 'solid',
					borderWidth: borderWidth
				}}
				onTouchStart={(e) => this.answerGridClick(value, index, 1, e)}
				onMouseDown={(e) => this.answerGridClick(value, index, 2, e)}
			/> 
		)
	}

	round(x,n){return Math.round(x*(10**n))/(10**n)}  //rounds x to the nth decimal place 
	
	render() {   // this is the REACT render function that renders everything to the DOM
		const { entryCode } = this.props
		const { showInstructions, showSettings, testCompleted, selectionBoxes, answerBoxes, stage, answerGridInstruction, selectionGridInstruction, countdownValue } = this.state
		return (
			<div>
				<div className="CatsAndDogs">
					<h1 style={{position: 'absolute', top: (this.answerGridBottom+10), left: 50}}>{answerGridInstruction}</h1>
					{stage !== 'presentation' && <h1 style={{position: 'absolute', top: (this.selectionGridBottom+10), left: (this.selectionGrid.x0+50)}}>{selectionGridInstruction}</h1>}
					{(countdownValue > 0) && <h1 style={{position: 'absolute', top: '230px', left: 200, width: 600, fontSize: '300px', color: 'silver', textAlign: 'center'}}>{countdownValue}</h1>}
					{(countdownValue > 0) && <h1 style={{position: 'absolute', top: '130px', left: 200, width: 600, fontSize: '100px', color: 'silver', textAlign: 'center'}}>Please wait...</h1>}
					
					{selectionBoxes.map((value, index) => 
						<div>
							{!showInstructions && (stage !== 'presentation' && stage !== 'delay') && this.renderSelectionBox(value,index)}  				
						</div>
					)}
					{answerBoxes.map((value, index) => 
						<div>
							{ (stage !== 'delay') && this.renderAnswerBox(value,index)}  			
						</div>
					)}
				</div>
				{!testCompleted && showSettings && this.renderSettingsModal()}
				{!testCompleted && !showSettings && showInstructions && this.renderInstructions('A1')}
				{testCompleted && this.props.testDone()}
				{testCompleted && this.props.memComplete(this.data) && <PatientQuestionnaire entryCode={entryCode} testID='mem'/>}
				
				{this.debugMode && <p> {this.roundTicks} </p>}
    		</div>
		);
	}
}

/*
<SendData entryCode={this.props.entryCode} testID='mem' header='Test Complete!'/>
{!testCompleted && showInstructions && this.renderInstructions(currentStage)}
{!testCompleted && !showSettings && showInstructions && this.renderInstructions()}
								

{this.state.selectionBoxes.map((value, index) => 
						<div>
							{!showInstructions && this.renderSelectionBox(value,index)}  
							{!showInstructions && value.correct  && this.renderSelectionBox(value,index)}					
						</div>
					)}
{this.state.answerBoxes.map((value, index) => 
						<div>
							{!showInstructions && this.renderAnswerBox(value,index)}  
							{!showInstructions && value.correct  && this.renderAnswerBox(value,index)}					
						</div>
					)}
*/

function mapDispatchToProps(dispatch) {  //used for exporting data after test completion
    return bindActionCreators({ memComplete: memComplete}, dispatch)
}

export default connect(null, mapDispatchToProps)(MEM);
