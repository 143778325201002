    // @ts-check
    import React from 'react';
    import dog from '../imgs/dog.png';
    import cat from '../imgs/cat.png';
    // import star3 from '../imgs/star3.png';
    import sndDone from '../audio/coinflip190m.wav';
    import sndCorrect from '../audio/ding240m.wav';
    import sndIncorrect from '../audio/incorrect100m.wav';
    import instruct1 from '../audio/instructions2_dcog_stage1_allison_low.mp3';
    import instruct3 from '../audio/instructions2_dcog_stage3_allison_low.mp3';
    import '../../../App.css'
    import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
    import { bindActionCreators } from 'redux';
    import { connect } from 'react-redux';
    import { cadNoMemComplete } from '../../../actions/CatsAndDogsNoMemComplete'
    //import CardSubtitle from 'reactstrap/lib/CardSubtitle';
    import PatientQuestionnaire from '../../../PatientQuestionnaire'
    import { Redirect } from 'react-router-dom';
    import { autoDCogIncrement, autoDCogShortComplete, autoDCogLongComplete } from '../../../actions/AUTOComplete'


    class CatsAndDogsNoMem extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                showInstructions: true,
                boxes: this.conformation18.slice(0,10),
                CatsAndDogsNoMemComplete: false,
                showStim: false, 
                respondToStim: false, 
                boxOpacity: 0,
                instructAudioFinished: false
            }
        }
        // USED FOR TESTING ONLY
        debugMode = false

        // The data that eventually gets sent to AWS
        data = [{ ver: 'D-Cog JS v1.05 (3/30/21)', date: Date() }]  //First element of the data array has task version info (title, version, revdate) and test date/time
        clicks = []
        levels = []
        
        myAudio = this.setupWebAudio()
        conformation18 = this.pickconformations()
        instructAudioStarted = false
        currentStage = 1
        animateTicks = this.props.animateTicks0   // Note that these time values are in timer ticks units, 
		roundTicks = this.props.roundTicks0       // based on timerInterval below (10ms) - used in setInterval fns
        stage1Ticks = (this.props.testMode==='Demo') ? 1500 : 10500  // Demo vs Normal test modes
        stage3Ticks = (this.props.testMode==='Demo') ? 1000 : 1500   // 'Demo' gets set using a URLSearch
        tTest = 0
        tStage1 = 0
        tStage3 = 0
		tInstruct = 0
		tStage1Instruct = 0
        tStage3Instruct = 0
        earlyStopping = false
        topDogs = 1  
		levelCombo = 1   // acceleration term for adaptive increases (if 1, take an extra step up after correct response)
		correctItem = 'dog'
                
        nCorrect = []
        nCorrectMax = []
        nIncorrect = []
        nIncorrectBlank= []
        nIncorrectLure= []
        summaryScore = 0
        summaryBestRound = 0 
        summaryCorrectRounds = 0 
        summaryTotalRounds = 0

        roundStartTime = null
        stageStartTime = null
        testStartTime = Date.now()

		static defaultProps = {     // Constant Param Settings 
			timerInterval: 10,      // the timer tick value [in msec], i.e. the interval used for all setinterval timers
			postTapTicks: 60,       // persistence time for feedback from the last target in each stage [in timer ticks]
			roundTicks0: 400,
			animateTicks0: 300,
			clickIntervalTicks: 150, // Extra time added if click was correct
            maxDogs: 9,              // Ceiling for adaptive increases in the number of dogs (or cats)
            instructTicks1: 300,     // Stage 1 voice instructions are about 3sec long (latency unit start button enabled)
            instructTicks3: 400      // Stage 3 voice instructions are about 11sec long
        }  
	    
        componentDidMount() {
            this.imgDog = new Image();
            this.imgCat = new Image();
            this.imgDog.src = dog; // by setting an src, we hopefully trigger browser download to preload the images
            this.imgCat.src = cat;
        }

        setupWebAudio() {
            const sndList = [sndCorrect, sndIncorrect, sndDone] //, instruct1, instruct3] 
            const myAudio = {context: null, buffer: [], bufferLoaded: [], iCorrect: 0, iIncorrect: 1, iAlert: 2, iInstructA: 3, iInstructB: 4 }	
            window.AudioContext = window.AudioContext||window.webkitAudioContext;
            myAudio.context = new AudioContext();//myAudio.context = new AudioContext(window.AudioContext || window.webkitAudioContext);
            for (let i = 0; i < sndList.length; i++) {
                myAudio.bufferLoaded[i]=false                   //initialization allowing us to check beffer length later on
                const request = new XMLHttpRequest();
                request.open('GET', sndList[i], true);
                request.responseType = 'arraybuffer';
                request.onload = function() {
                    myAudio.context.decodeAudioData(request.response, function(theBuffer) {        // Decode asynchronously
                    myAudio.buffer[i] = theBuffer;
                    myAudio.bufferLoaded[i]=true                   //initialization allowing us to check beffer length later on
                    }, e => console.log('sound load message: [' + i + '] ' + e) );
                }
                request.send();
            }
            return myAudio
        }

        playWebAudio(bufferIndex) {
            const source = this.myAudio.context.createBufferSource()
            source.buffer = this.myAudio.buffer[bufferIndex]
            source.connect(this.myAudio.context.destination)
            source.start(0)
        }

        pickconformations() {
            const confs = [[{'x': 150, 'y': 320}, {'x': 825, 'y': 445}, {'x': 725, 'y': 145}, {'x': 575, 'y': 570}, {'x': 650, 'y': 370}, {'x': 325, 'y': 195}, {'x': 350, 'y': 370}, {'x': 150, 'y': 145}, {'x': 825, 'y': 270}, {'x': 125, 'y': 520}, {'x': 500, 'y': 145}, {'x': 450, 'y': 570}, {'x': 325, 'y': 570}, {'x': 475, 'y': 345}, {'x': 700, 'y': 570}, {'x': 825, 'y': 570}, {'x': 230, 'y': 450}, {'x': 610, 'y': 250}],
                [{'x': 75, 'y': 120}, {'x': 500, 'y': 545}, {'x': 850, 'y': 195}, {'x': 375, 'y': 495}, {'x': 125, 'y': 345}, {'x': 425, 'y': 145}, {'x': 700, 'y': 145}, {'x': 400, 'y': 345}, {'x': 525, 'y': 345}, {'x': 125, 'y': 570}, {'x': 775, 'y': 420}, {'x': 650, 'y': 270}, {'x': 300, 'y': 170}, {'x': 750, 'y': 545}, {'x': 250, 'y': 470}, {'x': 650, 'y': 395}, {'x': 250, 'y': 320}, {'x': 530, 'y': 180}],
                [{'x': 600, 'y': 470}, {'x': 50, 'y': 445}, {'x': 200, 'y': 270}, {'x': 775, 'y': 570}, {'x': 450, 'y': 170}, {'x': 550, 'y': 345}, {'x': 675, 'y': 270}, {'x': 50, 'y': 270}, {'x': 325, 'y': 370}, {'x': 675, 'y': 120}, {'x': 300, 'y': 520}, {'x': 825, 'y': 120}, {'x': 425, 'y': 570}, {'x': 175, 'y': 420}, {'x': 850, 'y': 245}, {'x': 300, 'y': 120}, {'x': 800, 'y': 400}, {'x': 115, 'y': 140}],
                [{'x': 200, 'y': 495}, {'x': 425, 'y': 170}, {'x': 750, 'y': 370}, {'x': 800, 'y': 120}, {'x': 600, 'y': 495}, {'x': 150, 'y': 295}, {'x': 575, 'y': 370}, {'x': 775, 'y': 545}, {'x': 300, 'y': 295}, {'x': 700, 'y': 245}, {'x': 50, 'y': 170}, {'x': 175, 'y': 120}, {'x': 400, 'y': 520}, {'x': 850, 'y': 245}, {'x': 650, 'y': 120}, {'x': 75, 'y': 420}, {'x': 440, 'y': 360}, {'x': 300, 'y': 120}],
                [{'x': 325, 'y': 320}, {'x': 800, 'y': 320}, {'x': 575, 'y': 195}, {'x': 200, 'y': 420}, {'x': 75, 'y': 195}, {'x': 300, 'y': 195}, {'x': 350, 'y': 495}, {'x': 825, 'y': 120}, {'x': 150, 'y': 570}, {'x': 600, 'y': 420}, {'x': 725, 'y': 570}, {'x': 700, 'y': 195}, {'x': 450, 'y': 220}, {'x': 450, 'y': 370}, {'x': 575, 'y': 570}, {'x': 75, 'y': 445}, {'x': 190, 'y': 250}, {'x': 460, 'y': 550}],
                [{'x': 475, 'y': 420}, {'x': 675, 'y': 245}, {'x': 100, 'y': 270}, {'x': 800, 'y': 220}, {'x': 275, 'y': 195}, {'x': 275, 'y': 320}, {'x': 725, 'y': 495}, {'x': 100, 'y': 145}, {'x': 275, 'y': 545}, {'x': 475, 'y': 245}, {'x': 825, 'y': 345}, {'x': 100, 'y': 420}, {'x': 400, 'y': 120}, {'x': 625, 'y': 370}, {'x': 650, 'y': 120}, {'x': 100, 'y': 570}, {'x': 590, 'y': 550}, {'x': 380, 'y': 345}],
                [{'x': 600, 'y': 495}, {'x': 700, 'y': 220}, {'x': 575, 'y': 195}, {'x': 150, 'y': 345}, {'x': 75, 'y': 470}, {'x': 300, 'y': 420}, {'x': 425, 'y': 195}, {'x': 425, 'y': 345}, {'x': 825, 'y': 170}, {'x': 600, 'y': 345}, {'x': 800, 'y': 520}, {'x': 250, 'y': 195}, {'x': 200, 'y': 570}, {'x': 450, 'y': 520}, {'x': 325, 'y': 545}, {'x': 850, 'y': 320}, {'x': 110, 'y': 170}, {'x': 710, 'y': 410}],
                [{'x': 100, 'y': 395}, {'x': 325, 'y': 545}, {'x': 550, 'y': 495}, {'x': 775, 'y': 245}, {'x': 775, 'y': 520}, {'x': 225, 'y': 320}, {'x': 375, 'y': 395}, {'x': 525, 'y': 195}, {'x': 525, 'y': 370}, {'x': 700, 'y': 120}, {'x': 325, 'y': 120}, {'x': 75, 'y': 570}, {'x': 100, 'y': 270}, {'x': 375, 'y': 245}, {'x': 150, 'y': 145}, {'x': 650, 'y': 345}, {'x': 210, 'y': 500}, {'x': 655, 'y': 580}],
                [{'x': 450, 'y': 195}, {'x': 625, 'y': 120}, {'x': 600, 'y': 520}, {'x': 125, 'y': 145}, {'x': 450, 'y': 395}, {'x': 800, 'y': 370}, {'x': 275, 'y': 270}, {'x': 300, 'y': 120}, {'x': 200, 'y': 470}, {'x': 850, 'y': 495}, {'x': 75, 'y': 570}, {'x': 475, 'y': 570}, {'x': 650, 'y': 395}, {'x': 575, 'y': 245}, {'x': 825, 'y': 195}, {'x': 75, 'y': 420}, {'x': 340, 'y': 510}, {'x': 150, 'y': 290}],
                [{'x': 800, 'y': 370}, {'x': 175, 'y': 195}, {'x': 475, 'y': 220}, {'x': 675, 'y': 295}, {'x': 75, 'y': 470}, {'x': 325, 'y': 445}, {'x': 150, 'y': 345}, {'x': 300, 'y': 195}, {'x': 800, 'y': 220}, {'x': 600, 'y': 170}, {'x': 625, 'y': 445}, {'x': 675, 'y': 570}, {'x': 200, 'y': 545}, {'x': 50, 'y': 145}, {'x': 825, 'y': 495}, {'x': 450, 'y': 345}, {'x': 475, 'y': 510}, {'x': 290, 'y': 320}],
                [{'x': 400, 'y': 295}, {'x': 675, 'y': 520}, {'x': 750, 'y': 320}, {'x': 250, 'y': 495}, {'x': 425, 'y': 495}, {'x': 200, 'y': 195}, {'x': 75, 'y': 120}, {'x': 450, 'y': 120}, {'x': 825, 'y': 120}, {'x': 600, 'y': 195}, {'x': 800, 'y': 545}, {'x': 325, 'y': 145}, {'x': 575, 'y': 370}, {'x': 50, 'y': 445}, {'x': 275, 'y': 345}, {'x': 550, 'y': 545}, {'x': 115, 'y': 315}, {'x': 710, 'y': 135}],
                [{'x': 675, 'y': 195}, {'x': 525, 'y': 470}, {'x': 750, 'y': 545}, {'x': 200, 'y': 170}, {'x': 100, 'y': 295}, {'x': 400, 'y': 345}, {'x': 50, 'y': 520}, {'x': 675, 'y': 320}, {'x': 850, 'y': 270}, {'x': 400, 'y': 470}, {'x': 225, 'y': 495}, {'x': 550, 'y': 295}, {'x': 275, 'y': 345}, {'x': 375, 'y': 170}, {'x': 50, 'y': 120}, {'x': 825, 'y': 420}, {'x': 510, 'y': 125}, {'x': 640, 'y': 485}],
                [{'x': 600, 'y': 395}, {'x': 725, 'y': 320}, {'x': 675, 'y': 145}, {'x': 250, 'y': 295}, {'x': 350, 'y': 445}, {'x': 850, 'y': 170}, {'x': 375, 'y': 120}, {'x': 225, 'y': 420}, {'x': 75, 'y': 295}, {'x': 75, 'y': 570}, {'x': 850, 'y': 295}, {'x': 525, 'y': 120}, {'x': 425, 'y': 570}, {'x': 725, 'y': 495}, {'x': 550, 'y': 270}, {'x': 175, 'y': 120}, {'x': 415, 'y': 300}, {'x': 845, 'y': 550}],
                [{'x': 200, 'y': 220}, {'x': 250, 'y': 470}, {'x': 475, 'y': 120}, {'x': 775, 'y': 220}, {'x': 500, 'y': 320}, {'x': 175, 'y': 345}, {'x': 325, 'y': 145}, {'x': 50, 'y': 520}, {'x': 625, 'y': 570}, {'x': 50, 'y': 220}, {'x': 625, 'y': 445}, {'x': 375, 'y': 295}, {'x': 750, 'y': 420}, {'x': 375, 'y': 445}, {'x': 600, 'y': 195}, {'x': 850, 'y': 570}, {'x': 130, 'y': 120}, {'x': 500, 'y': 500}],
                [{'x': 50, 'y': 570}, {'x': 400, 'y': 545}, {'x': 250, 'y': 445}, {'x': 625, 'y': 345}, {'x': 850, 'y': 445}, {'x': 425, 'y': 320}, {'x': 600, 'y': 170}, {'x': 775, 'y': 195}, {'x': 150, 'y': 295}, {'x': 725, 'y': 520}, {'x': 275, 'y': 570}, {'x': 525, 'y': 545}, {'x': 475, 'y': 195}, {'x': 775, 'y': 320}, {'x': 100, 'y': 120}, {'x': 350, 'y': 170}, {'x': 120, 'y': 430}, {'x': 230, 'y': 150}],
                [{'x': 400, 'y': 170}, {'x': 400, 'y': 470}, {'x': 525, 'y': 470}, {'x': 825, 'y': 195}, {'x': 700, 'y': 195}, {'x': 275, 'y': 445}, {'x': 650, 'y': 370}, {'x': 750, 'y': 545}, {'x': 150, 'y': 495}, {'x': 250, 'y': 145}, {'x': 775, 'y': 370}, {'x': 100, 'y': 320}, {'x': 525, 'y': 295}, {'x': 100, 'y': 120}, {'x': 300, 'y': 295}, {'x': 575, 'y': 145}, {'x': 630, 'y': 580}, {'x': 410, 'y': 320}],
                [{'x': 475, 'y': 395}, {'x': 725, 'y': 395}, {'x': 475, 'y': 545}, {'x': 300, 'y': 170}, {'x': 150, 'y': 520}, {'x': 100, 'y': 120}, {'x': 300, 'y': 470}, {'x': 650, 'y': 145}, {'x': 450, 'y': 270}, {'x': 825, 'y': 545}, {'x': 50, 'y': 370}, {'x': 600, 'y': 295}, {'x': 700, 'y': 520}, {'x': 775, 'y': 220}, {'x': 175, 'y': 245}, {'x': 450, 'y': 120}, {'x': 300, 'y': 320}, {'x': 840, 'y': 375}],
                [{'x': 125, 'y': 395}, {'x': 650, 'y': 145}, {'x': 450, 'y': 495}, {'x': 300, 'y': 520}, {'x': 800, 'y': 545}, {'x': 75, 'y': 270}, {'x': 200, 'y': 245}, {'x': 325, 'y': 195}, {'x': 850, 'y': 420}, {'x': 650, 'y': 270}, {'x': 675, 'y': 470}, {'x': 350, 'y': 345}, {'x': 475, 'y': 320}, {'x': 825, 'y': 270}, {'x': 50, 'y': 520}, {'x': 50, 'y': 145}, {'x': 570, 'y': 565}, {'x': 475, 'y': 130}],
            ]
        confs.sort(() => Math.random() - 0.5);                   
            return confs[0]   //pick a conformation row at random (row 0 of the RANDOMLY RESORTED array)
        }

		// Performs a 2-level clone for an array of objects (object fields need be individual values for this to be a complete value clone as this only goes 2-Levels down)
		cloneArray(x) {    
			let xClone=[]
			for (let i = 0; i < x.length; i++) { xClone[i]={...x[i]} }
			return xClone
		}

        // THE TIMER THAT RUNS FOR AN ENTIRE STAGE OF THE TEST
        stageTimer(stage) {
            if(stage === 1){
                this.stageInterval = setInterval(() => {
                    this.stage1Ticks = Math.max(this.stage1Ticks - 1, 0)
                    if(this.stage1Ticks === 0){
                        clearInterval(this.stageInterval)
                    }
                }, this.props.timerInterval)
            }
            if(stage === 3){
                this.stageInterval = setInterval(() => {
                    this.stage3Ticks =  Math.max(this.stage3Ticks - 1, 0) 
                    if(this.stage3Ticks === 0){
                        clearInterval(this.stageInterval)
                    }
                }, this.props.timerInterval)
            }
        }

        // TIMER THAT RUNS FOR EACH TESTING ROUND INSIDE OF A STAGE
        roundTimer() {
            this.roundStartTime = Date.now()
            this.roundInterval = setInterval(() => {
                const { currentStage, stage1Ticks, stage3Ticks } = this    
                this.roundTicks = Math.max(this.roundTicks - 1, 0)
                    if(this.roundTicks === 0){
                        clearInterval(this.roundInterval)
                        this.roundEnd();
                        ((currentStage === 1 && stage1Ticks === 0) || (currentStage === 3 && stage3Ticks === 0)) ? this.stageEnd() : this.roundStart()
                    }
            }, this.props.timerInterval)
        }

        // THE CLICK ON EVERY BUTTON CLICK
        boxClick(value, index, eventType, e) {
            const { boxes } = this.state
            const { animateTicks } = this
            if (!boxes[index].isClicked && animateTicks === 0) {
                boxes[index].isClicked = true
                const time = (Date.now() - this.roundStartTime) / 1000
                this.checkCorrect(boxes[index])
                let clickXY = { xc: e.nativeEvent.offsetX, yc: e.nativeEvent.offsetY }
                const {x, y, type, correct} = boxes[index]
                this.clicks.push({ ...clickXY, x: x, y: y, t: time, item: type, correct: correct } )
				this.setState({boxes: boxes})
            }
        }

        // USED TO SHOW INSTRUCTIONS BEFORE THE DIFFERENT STAGES OF TESTING
        toggleInstructions = () => {
            this.playWebAudio(this.myAudio.iAlert)
            this.instructAudioStarted = false
		    this.setState({
                instructAudioFinished: false,
                showInstructions: false, 
            })
			this.tInstruct = (Date.now() - this.instructStartTime)/1000
            this.stageStart()
        }

        // CHECKS TO SEE IF THE SELECTED BOX WAS CORRECT, IF SO ADD MORE TIME TO THE CURRENT ROUND.
        // IF IT IS THE FINAL CORRECT ANSWER FOR THE ROUND THEN END THE ROUND EARLY
        checkCorrect(updatedBox) {
            const { boxes} = this.state
            const { myAudio, correctItem, currentStage, levels } = this
            if(updatedBox.type === correctItem) {
                updatedBox.correct = true
                this.roundTicks = Math.max(this.roundTicks, this.props.clickIntervalTicks)
                for (let i = 0; i < boxes.length; i++) {        
                    if(boxes[i].type === correctItem && !boxes[i].isClicked){
                        this.playWebAudio(myAudio.iCorrect)
						return          // IF THERE IS STILL A CORRECT BOX UNCLICKED, PLAY THE 'CORRECT' SOUND & RETURN
                    }
                }
				this.playWebAudio(myAudio.iAlert)  //PLAY THE 'ALERT' SOUND IF THE LAST BOX HAS BEEN CORRECTLY CLICKED!
            } else {
                updatedBox.correct = false
                this.playWebAudio(myAudio.iIncorrect)
                return                      // IF AN INCORRECT BOX WAS CLICKED, RETURN
            }
            if(currentStage === 1){         // UPDATE THE TOP DOGS TO THE NEW DOGS
                this.topDogs = Math.max(this.topDogs,levels[levels.length - 1].dogs)  
            }
            this.earlyStopping =  true
            this.roundTicks = this.props.postTapTicks  // this allows time for the click feedback to be shown before starting the next round
        }

        // CALLED AT THE BEGINNING OF EACH ROUND
        roundStart() {
            this.roundTicks = this.props.roundTicks0  // reset round seconds
            this.clicks = []
            this.earlyStopping = false
            this.animateTicks = this.props.animateTicks0		
            this.resetAndShuffleBoxes()  // Shuffle Dogs and Cats		
            this.animateBoxes()        // Animate
        }

        // CALLED AT THE END OF EACH ROUND
        roundEnd() {
            const { boxes } = this.state
            const { levels, correctItem, nCorrect, nIncorrect, nIncorrectBlank, nIncorrectLure, nCorrectMax } = this            
            // COUNT THE CORRECT and INORRECT CLICKED and TOTAL BOXES
            const j = levels.length - 1
            nCorrect[j] = 0
            nIncorrect[j] = 0
            nIncorrectBlank[j] = 0
            nIncorrectLure[j] = 0
            for (let i = 0; i < boxes.length; i++) {
                if(boxes[i].type === correctItem && boxes[i].isClicked){
                    nCorrect[j]++
                } else if(boxes[i].type === null && boxes[i].isClicked){
                    nIncorrect[j]++
                    nIncorrectBlank[j]++
                } else if(boxes[i].isClicked){
                    nIncorrect[j]++
                    nIncorrectLure[j]++
                }
            }
            nCorrectMax[j] = (correctItem === 'dog') ? levels[j].dogs : levels[j].cats
            this.data.push({ nCorrect: nCorrect[j], nIncorrect: nIncorrect[j], nIncorrectBlank: nIncorrectBlank[j], nIncorrectLure: nIncorrectLure[j] })
            this.data.push({ target: correctItem, ...levels[j], boxes: boxes.length, clicks: this.clicks, boxinfo: this.cloneArray(boxes) })
            this.createNextLevel(this.earlyStopping && nIncorrect[j] === 0)   //argument is true for perfect round
        }	

        // CREATE THE LEVEL BASED ON LEVELCOMBO AND EARLY STOPPING
        createNextLevel(perfectPreviousLevel) {
            const { levels, currentStage } = this
            const { maxDogs } = this.props
            const currentLevel = levels[levels.length - 1]
            this.levelCombo = (currentLevel.dogs >= 5 || !perfectPreviousLevel) ? 0 : this.levelCombo
            const newDogs = (perfectPreviousLevel) ? Math.min(currentLevel.dogs + this.levelCombo + 1, maxDogs) : Math.max(currentLevel.dogs - 1, 1)
            const newCats = (currentStage === 3) ? newDogs : 0
            levels.push({'dogs': newDogs, 'cats': newCats})
        }

        // RESETS THE BOXES BACK TO THEIR ORIGINAL STATE & RANDOMLY SHUFFLES THEM SO ANIMALS ARE ASSIGNED TO RANDOM LOCATIONS
        resetAndShuffleBoxes() {
            const { levels } = this
			const dogs = levels[levels.length - 1].dogs
            const cats = levels[levels.length - 1].cats
            //const boxes = (dogs > 5) ? this.conformation18:this.conformation10 
            //const boxes = this.conformation18.slice(0, Math.max(10, 2*dogs + 2))  // 1-4 dogs --> 10 boxes, 5-->12, 6-->14, 7-->16, 8-->18
            const boxes = this.conformation18.slice(0, 2*dogs + 2)  // 1 dog --> 4 boxes, 2-->6, 3-->8, 4--> 10, 5-->12, 6-->14, 7-->16, 8-->18 etc
            const shuffled = boxes.sort(() => Math.random() - 0.5);
            for(let i = 0; i < boxes.length; i++){
                shuffled[i].isClicked = false
                shuffled[i].correct = null
                shuffled[i].type = (i < dogs) ? 'dog' : (i < dogs+cats) ? 'cat' : null
            }
            this.setState({boxes: shuffled})
        }

        animateBoxes() {
            this.animateInterval = setInterval(() => {
				let { boxOpacity, showStim, respondToStim } = this.state
				const boxOpacity0 = boxOpacity;
				const showStim0 = showStim
				const respondToStim0 = respondToStim
                this.animateTicks = Math.max(this.animateTicks - 1, 0)
                if ( this.animateTicks > 0 && this.animateTicks <= 300 ) { 
                    showStim = true
                    respondToStim = false
                    boxOpacity =  Math.min((300-this.animateTicks)/20,1)        //fade on for 1st 20 ticks (200ms) them maintain full brightness for next 280 ticks (2.8sec)
                } else if ( this.animateTicks > 0 && this.animateTicks <= 0 ) { //this WAS 0 to 100 ticks before, now this is orphaned
                    showStim = false
                    respondToStim = false
                    boxOpacity =  1
                } else if ( this.animateTicks === 0 ) {
                    showStim = false
                    respondToStim = true
					boxOpacity =  1
                    clearInterval(this.animateInterval)
                    this.roundTimer()
                }
				if (boxOpacity !== boxOpacity0 || showStim !== showStim0 || respondToStim !== respondToStim0) { 
					this.setState({         // using *if* above so that setState is only called when a change occurs
                    	showStim: showStim,
                    	respondToStim: respondToStim,
                        boxOpacity: boxOpacity,
                	})
				}
            }, this.props.timerInterval)
        }

        // CALLED AT THE BEGINNING OF A STAGE
        stageStart() {
            const { currentStage, levels } = this
            const numDogs = Math.max(this.topDogs-1, 1)   // CREATE THE SPECIAL LEVEL USING topDogs
            const numCats = (currentStage === 3) ? numDogs : 0
            levels.push({'dogs': numDogs, 'cats': numCats})
            this.stageStartTime = Date.now()
            this.stageTimer(currentStage)
            this.roundStart() 
        }

        // CALLED AT THE END OF A STAGE
        stageEnd() {
            const { currentStage, levels } = this
            levels.pop()     //remove level just created by createNextLevel() which won't be run
            if (currentStage === 1) {
                this.currentStage = 3
				this.correctItem = 'cat'
				this.levelCombo = 0
                this.tStage1Instruct = this.round(this.tInstruct,1)
				this.tStage1 = this.round((Date.now()-this.stageStartTime)/1000,1)
                this.setState({ showInstructions: true })
            } else {
                let score = this.irtScore()
                this.tStage3Instruct = this.round(this.tInstruct,1)
            	this.tStage3 = this.round((Date.now()-this.stageStartTime)/1000,1)
				this.tTest = this.round((Date.now()-this.testStartTime)/1000,1)
                this.summaryScore = score.score
                this.summaryBestRound = score.bestRound 
                this.summaryCorrectRounds = score.correctRounds 
                this.summaryTotalRounds = score.totalRounds
                const { tTest, tStage1Instruct, tStage1, tStage3Instruct, tStage3 } = this
                this.data.push({ score: score.score, bestRound: score.bestRound, correctRounds: score.correctRounds, totalRounds: score.totalRounds, stage1Instruct: tStage1Instruct, stage1Time: tStage1, stage3Instruct: tStage3Instruct, stage3Time: tStage3, testDuration: tTest })
                this.setState({ catsAndDogsComplete: true })
            }
        }

        toggle = () => {
            this.props.cadNoMemComplete(this.data)
        }

        irtScore() {
            /* 
            Function description:
                Item response theory (IRT) makes a statistical estimate of the breakpoint difficulty 
                level that divides when a task can usually be performed correctly vs not. First, 
                Item Response Function is defined as follow:
                    IRF = exp(k*(theta-b)) / (1 + exp(k*(theta-b)))
                , where k is reliability and theta is ... Then, using IRF allows the likelihood 
                to be computed for every possible performance level model estimates as follows.
                    theta_hat = argmax{ IRF^y * (1-IRF^(1-y)) }
            
            Input variables:
                nCorrectMax: number of correctItems that could be clicked (dogs in Stage1, cats in Stage2)
                nCorrect: quantittive vector of task success, [# Dogs (Stage1) or Cats (Stage3)) that were clicked]
                nInorrect: the number of incorrect boxes that were clicked [blanks in S1, blanks + lures (dogs) in S2]
            
            Intermediate variables:
                b: vector of task difficulties (based on nCorrectMax, but expanded as noted below).
                y: binary vector of task success, [1: success (all correct items found w/ no incorrect), 0: failure/imperfect]
                
                Note that b & y will generally have more entries than the raw input (# of rounds) because each imperfect 
                response will generate 2 entries: one incrrect entry and an additional 'partial credit' correct entry 
                based on (nCorrect - nIncorrect)
            
            Output variables
                theta[index]: estimates of ...
            */
            const { nCorrect, nIncorrect, nCorrectMax} = this
            const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
            let k = 2.00;   // changed from 5 which is probably too narrow/specific
            let theta = range(0, 10, 0.1);
            let b =[]
            let y =[]
            let ii = -1
            let nPerfect = 0
            let bestRound = 0
            for (let i = 0; i < nCorrect.length; i++) {                // preprocessing of the data
                if (nCorrect[i]===nCorrectMax[i] && nIncorrect[i]===0) {
                    bestRound = Math.max(bestRound,nCorrect[i])
                    nPerfect++
                    ii++
                    y[ii]=1               // standard correct entry for a perfectly correct response
                    b[ii]=nCorrectMax[i]
                } else {
                    ii++
                    y[ii]=0               // standard incorrect entry for an imperfect response
                    b[ii]=nCorrectMax[i]
                    ii++
                    y[ii]=1               // additional 'partial credit' correct entry for an imperfect response
                    b[ii]=nCorrect[i]-nIncorrect[i]
                }
            }
            // item response theory computation
            let irf = 0.00;
            let irt = Array(theta.length).fill(0)
            for (let i = 0; i < y.length; i++) {
                for (let j = 0; j < theta.length; j++) {
                    irf = Math.exp(k * (theta[j] - b[i])) / (1 + Math.exp(k * (theta[j] - b[i])))
                    irt[j] += Math.log(Math.pow(irf, y[i]) * Math.pow(1 - irf, 1 - y[i]))
                }
            }
            let index = irt.indexOf(Math.max(...irt))
            let score = Math.round(theta[index]*10)/10
            return {score: score, bestRound: bestRound, correctRounds: nPerfect, totalRounds: nCorrect.length }
        }

        playSound(snd) {
            const correctAudio = new Audio(snd)
            correctAudio.play()
        }

        speakInstructions = () => {
            const { currentStage } = this
            const { instructTicks1, instructTicks3 } = this.props
            if (!this.instructAudioStarted) {
                this.playSound((currentStage === 1) ? instruct1 : instruct3)
                this.instructStartTime = Date.now()
                this.instructAudioStarted = true
				this.setState({showInstructions: this.state.showInstructions})  // Hack to trigger render() (even though showInstructions unchanged)
                let ticks = (currentStage === 1) ? instructTicks1 : instructTicks3
			    this.instructInterval = setInterval(() => {  // TIMER THAT RUNS TO ENABLE START BUTTON AFTER VOICE INSTRUCTIONS FINISHED
				ticks--
				if (ticks <= 0){
						clearInterval(this.instructInterval)
						this.setState({ instructAudioFinished: true })
					}
			}, this.props.timerInterval)
            }
            
        }
        
        renderBoxCover(value, index) {
            const bgColor = (value.correct || value.correct === null) ? 'blue' : 'gray'
            const boxOpacity = value.correct ? 0 : 1     // if correct, make the boxcover clear to reveal the tgt (dog or cat) else show a solid gray opaque cover
            return(
                <div
                    style={{
                        position: 'absolute',
                        width: '100px',
                        height: '100px',
                        top: value.y,
                        left: value.x,
                        backgroundColor: bgColor,
                        opacity: boxOpacity,
                        borderColor: 'blue',
                        borderStyle: 'solid',
                        borderWidth: '2px'
                    }}
                    onTouchStart={(e) => this.boxClick(value, index, 1, e)}
                    onMouseDown={(e) => this.boxClick(value, index, 2, e)}
                    //onClick={(e) => this.boxClick(value, index, 3, e)}
                > </div>
            )
        }

        renderBox(value, index, boxOpacity) {
            const imgsrc = (value.type === 'dog') ? this.imgDog.src : (value.type === 'cat') ? this.imgCat.src : null
            const bgColor = this.state.showStim ? 'white' : 'blue'
            return(
                <img src={imgsrc} style={{
                    position: 'absolute',
                    width: '100px',
                    height: '100px',
                    top: value.y,
                    left: value.x,
                    backgroundColor: bgColor,
                    opacity: boxOpacity,
                    borderColor: 'blue',
                    borderStyle: 'solid',
                    borderWidth: '2px'
                }}
                />)
        }

		renderInstructions(stage) {
            const { instructAudioFinished } = this.state
            if (stage === 1) {
				this.speakInstructions()
				return (
					<Modal centered={true} isOpen={true}>
             		 	<ModalBody className="text-center">When the squares turn blue, tap where the <b>dogs</b> were.</ModalBody>
            		  	<ModalFooter className="d-flex justify-content-center">
            		  	<Button disabled={!instructAudioFinished} color="primary" onClick={this.toggleInstructions}>Start</Button>
            		  	</ModalFooter>
          			</Modal>)
			} else if (stage === 3 && this.instructAudioStarted) {
				//this.speakInstructions()
				return (
					<Modal centered={true} isOpen={true}>
             		 	<ModalBody className="text-center">When the squares turn blue, tap where the <b>cats</b> were.  <b>Do NOT tap where the dogs were.</b></ModalBody>
            		  	<ModalFooter className="d-flex justify-content-center">
            		  	<Button disabled={!instructAudioFinished} color="primary" onClick={this.toggleInstructions}>Start</Button>
            		  	</ModalFooter>
          			</Modal>)
			} else if (stage === 3 && !this.instructAudioStarted) {  // Popup directly linked to instruction voice over so iOS deosn't suppress it!!!
				return (
					<Modal centered={true} isOpen={!this.instructAudioStarted}>
					  	<ModalBody className="text-center">Part 1 Complete!</ModalBody>
					  	<ModalFooter className="d-flex justify-content-center">
					  	<Button color="primary" onClick={this.speakInstructions}>Proceed</Button>
					  	</ModalFooter>
				  	</Modal>)
			}
		}

		round(x,n){return Math.round(x*(10**n))/(10**n)}  //rounds x to the nth decimal place 

        componentWillUnmount() {
            const { autoDCogIncrement, autoDCogShortComplete, autoDCogLongComplete, group, dcogRun, timeMode } = this.props
            if(group === null) {
                // THE DATA HAS BEEN SENT NORMALLY
                return
            } else {
                // SEND DATA TO AUTO REDUCER
                if (timeMode !== null && timeMode === 'short') {
                    autoDCogShortComplete({"run" : dcogRun, "data" : this.data})
                } else {
                    autoDCogLongComplete({"run" : dcogRun, "data" : this.data})
                }
                // AUTO INCREMENT DCOG COUNTER IF NECESSARY
                if(dcogRun < 3) {
                    autoDCogIncrement()
                }
            }
        }

        renderAutoRedirect = () => {
            const { dcogRun } = this.props
            switch(dcogRun) {
                case(1):
                    return <Redirect to='/auto/anart' />
                case(2):
                    return <Redirect to='/auto/demographics' />
                case(3):
                    return <Redirect to='/auto/complete' />
                default:
                    return
            }
        }

        render() {
            const { entryCode, group } = this.props
            const { showInstructions, boxes, catsAndDogsComplete, showStim, respondToStim, boxOpacity } = this.state
            const { currentStage, stage1Ticks, stage3Ticks, roundTicks, animateTicks } = this
            //note the double render (actually, the common initial render, regardless of what eventually gets rendered) under boxes.map below is used to maybe(?) make the scroll lock work on ios (unknown why??)
			return (
                <div>
                    <div className="CatsAndDogs">
                        {boxes.map((value, index) => 
                            <div>
                                {(showStim||respondToStim) && this.renderBox(value,index,boxOpacity)}
                                {respondToStim && this.renderBoxCover(value,index)}      
                            </div>
                        )}
                    </div>
                    {!catsAndDogsComplete && showInstructions && this.renderInstructions(currentStage)}
                    {catsAndDogsComplete && group === null && this.props.cadNoMemComplete(this.data) && <PatientQuestionnaire entryCode={entryCode} testID='catsanddogsnomem'/>}
                    {catsAndDogsComplete && group !== null && this.renderAutoRedirect()}
                    {this.debugMode && <p> {stage1Ticks} {stage3Ticks} {roundTicks} {animateTicks} </p>}
                </div>
            );
        }
    }

    /*
    {catsAndDogsComplete &&
                        <Modal centered={true} isOpen={clearModal} toggle={this.toggle}>
                            <ModalBody className="text-center">Test Complete!
							<br/>Score:  [{summaryScore}] [{summaryBestRound} {summaryCorrectRounds}/{summaryTotalRounds}]
							<br/>Time: [Total: {this.round(tTest,2)}]
							<br/>[S1: {this.round(tStage1Instruct,2)} {this.round(tStage1,2)}]
							<br/>[S3: {this.round(tStage3Instruct,2)} {this.round(tStage3,2)}]]
                            </ModalBody>
                    	</Modal>}
    */
    function mapStateToProps(state) {
        return {
            group: state.auto.group,
            dcogRun : state.auto.dcogRun
        }
    }

    function mapDispatchToProps(dispatch) {
        return bindActionCreators({
            cadNoMemComplete: cadNoMemComplete,
            autoDCogIncrement: autoDCogIncrement,
            autoDCogShortComplete: autoDCogShortComplete,
            autoDCogLongComplete: autoDCogLongComplete
        }, dispatch)
    }

    export default connect(mapStateToProps, mapDispatchToProps)(CatsAndDogsNoMem);
