import React from 'react';
import dog from '../imgs/dog.png';
import cat from '../imgs/cat.png';
import star3 from '../imgs/star3.png';
import dfy from '../audio/correct.mp3';
import '../../../App.css'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';


class CatsAndDogsNoMemPractice extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentStage: 1,
			levelCombo: 1,
			stage1Seconds: 10500,
			stage3Seconds: 1500,
			roundSeconds: 400,
			showInstructions: true,
			levels: [{dogs: 1, cats: 0}],
			boxes: this.confirmation10,
			animate: true,
			animateTime: 300,
			correctItem: 'dog',
			topDogs: 1,
			CatsAndDogsNoMemComplete: false,
			clearModal: true
		}
	}
	// USED FOR TESTING ONLY
	debugMode = false

	// The data that eventually gets sent to AWS
	data = []
	clicks = []

	confs = this.pickConfirmations()
	confirmation16 = this.confs.conf
	confirmation10 = this.confirmation16.slice(0, 10)

	// standard times for each stage
	roundSeconds = 400
	stage1Seconds = 4000
	stage3Seconds = 1500

	// Extra time added if click was correct
	clickIntervalTime = 150

	// Since clicking correct boxes can add extra time, we use js Dates to correctly calculate time
	roundStartTime = null

	pickConfirmations() {
		const confs = [[{'x': 150, 'y': 320}, {'x': 825, 'y': 445}, {'x': 725, 'y': 145}, {'x': 575, 'y': 570}, {'x': 650, 'y': 370}, {'x': 325, 'y': 195}, {'x': 350, 'y': 370}, {'x': 150, 'y': 145}, {'x': 825, 'y': 270}, {'x': 125, 'y': 520}, {'x': 500, 'y': 145}, {'x': 450, 'y': 570}, {'x': 325, 'y': 570}, {'x': 475, 'y': 345}, {'x': 700, 'y': 570}, {'x': 825, 'y': 570}],
			[{'x': 75, 'y': 120}, {'x': 500, 'y': 545}, {'x': 850, 'y': 195}, {'x': 375, 'y': 495}, {'x': 125, 'y': 345}, {'x': 425, 'y': 145}, {'x': 700, 'y': 145}, {'x': 400, 'y': 345}, {'x': 525, 'y': 345}, {'x': 125, 'y': 570}, {'x': 775, 'y': 420}, {'x': 650, 'y': 270}, {'x': 300, 'y': 170}, {'x': 750, 'y': 545}, {'x': 250, 'y': 470}, {'x': 650, 'y': 395}],
			[{'x': 600, 'y': 470}, {'x': 50, 'y': 445}, {'x': 200, 'y': 270}, {'x': 775, 'y': 570}, {'x': 450, 'y': 170}, {'x': 550, 'y': 345}, {'x': 675, 'y': 270}, {'x': 50, 'y': 270}, {'x': 325, 'y': 370}, {'x': 675, 'y': 120}, {'x': 300, 'y': 520}, {'x': 825, 'y': 120}, {'x': 425, 'y': 570}, {'x': 175, 'y': 420}, {'x': 850, 'y': 245}, {'x': 300, 'y': 120}],
			[{'x': 200, 'y': 495}, {'x': 425, 'y': 170}, {'x': 750, 'y': 370}, {'x': 800, 'y': 120}, {'x': 600, 'y': 495}, {'x': 150, 'y': 295}, {'x': 575, 'y': 370}, {'x': 775, 'y': 545}, {'x': 300, 'y': 295}, {'x': 700, 'y': 245}, {'x': 50, 'y': 170}, {'x': 175, 'y': 120}, {'x': 400, 'y': 520}, {'x': 850, 'y': 245}, {'x': 650, 'y': 120}, {'x': 75, 'y': 420}],
			[{'x': 325, 'y': 320}, {'x': 800, 'y': 320}, {'x': 575, 'y': 195}, {'x': 200, 'y': 420}, {'x': 75, 'y': 195}, {'x': 300, 'y': 195}, {'x': 350, 'y': 495}, {'x': 825, 'y': 120}, {'x': 150, 'y': 570}, {'x': 600, 'y': 420}, {'x': 725, 'y': 570}, {'x': 700, 'y': 195}, {'x': 450, 'y': 220}, {'x': 450, 'y': 370}, {'x': 575, 'y': 570}, {'x': 75, 'y': 445}],
			[{'x': 475, 'y': 420}, {'x': 675, 'y': 245}, {'x': 100, 'y': 270}, {'x': 800, 'y': 220}, {'x': 275, 'y': 195}, {'x': 275, 'y': 320}, {'x': 725, 'y': 495}, {'x': 100, 'y': 145}, {'x': 275, 'y': 545}, {'x': 475, 'y': 245}, {'x': 825, 'y': 345}, {'x': 100, 'y': 420}, {'x': 400, 'y': 120}, {'x': 625, 'y': 370}, {'x': 650, 'y': 120}, {'x': 100, 'y': 570}],
			[{'x': 600, 'y': 495}, {'x': 700, 'y': 220}, {'x': 575, 'y': 195}, {'x': 150, 'y': 345}, {'x': 75, 'y': 470}, {'x': 300, 'y': 420}, {'x': 425, 'y': 195}, {'x': 425, 'y': 345}, {'x': 825, 'y': 170}, {'x': 600, 'y': 345}, {'x': 800, 'y': 520}, {'x': 250, 'y': 195}, {'x': 200, 'y': 570}, {'x': 450, 'y': 520}, {'x': 325, 'y': 545}, {'x': 850, 'y': 320}],
			[{'x': 100, 'y': 395}, {'x': 325, 'y': 545}, {'x': 550, 'y': 495}, {'x': 775, 'y': 245}, {'x': 775, 'y': 520}, {'x': 225, 'y': 320}, {'x': 375, 'y': 395}, {'x': 525, 'y': 195}, {'x': 525, 'y': 370}, {'x': 700, 'y': 120}, {'x': 325, 'y': 120}, {'x': 75, 'y': 570}, {'x': 100, 'y': 270}, {'x': 375, 'y': 245}, {'x': 150, 'y': 145}, {'x': 650, 'y': 345}],
			[{'x': 450, 'y': 195}, {'x': 625, 'y': 120}, {'x': 600, 'y': 520}, {'x': 125, 'y': 145}, {'x': 450, 'y': 395}, {'x': 800, 'y': 370}, {'x': 275, 'y': 270}, {'x': 300, 'y': 120}, {'x': 200, 'y': 470}, {'x': 850, 'y': 495}, {'x': 75, 'y': 570}, {'x': 475, 'y': 570}, {'x': 650, 'y': 395}, {'x': 575, 'y': 245}, {'x': 825, 'y': 195}, {'x': 75, 'y': 420}],
			[{'x': 800, 'y': 370}, {'x': 175, 'y': 195}, {'x': 475, 'y': 220}, {'x': 675, 'y': 295}, {'x': 75, 'y': 470}, {'x': 325, 'y': 445}, {'x': 150, 'y': 345}, {'x': 300, 'y': 195}, {'x': 800, 'y': 220}, {'x': 600, 'y': 170}, {'x': 625, 'y': 445}, {'x': 675, 'y': 570}, {'x': 200, 'y': 545}, {'x': 50, 'y': 145}, {'x': 825, 'y': 495}, {'x': 450, 'y': 345}],
			[{'x': 400, 'y': 295}, {'x': 675, 'y': 520}, {'x': 750, 'y': 320}, {'x': 250, 'y': 495}, {'x': 425, 'y': 495}, {'x': 200, 'y': 195}, {'x': 75, 'y': 120}, {'x': 450, 'y': 120}, {'x': 825, 'y': 120}, {'x': 600, 'y': 195}, {'x': 800, 'y': 545}, {'x': 325, 'y': 145}, {'x': 575, 'y': 370}, {'x': 50, 'y': 445}, {'x': 275, 'y': 345}, {'x': 550, 'y': 545}],
			[{'x': 675, 'y': 195}, {'x': 525, 'y': 470}, {'x': 750, 'y': 545}, {'x': 200, 'y': 170}, {'x': 100, 'y': 295}, {'x': 400, 'y': 345}, {'x': 50, 'y': 520}, {'x': 675, 'y': 320}, {'x': 850, 'y': 270}, {'x': 400, 'y': 470}, {'x': 225, 'y': 495}, {'x': 550, 'y': 295}, {'x': 275, 'y': 345}, {'x': 375, 'y': 170}, {'x': 50, 'y': 120}, {'x': 825, 'y': 420}],
			[{'x': 600, 'y': 395}, {'x': 725, 'y': 320}, {'x': 675, 'y': 145}, {'x': 250, 'y': 295}, {'x': 350, 'y': 445}, {'x': 850, 'y': 170}, {'x': 375, 'y': 120}, {'x': 225, 'y': 420}, {'x': 75, 'y': 295}, {'x': 75, 'y': 570}, {'x': 850, 'y': 295}, {'x': 525, 'y': 120}, {'x': 425, 'y': 570}, {'x': 725, 'y': 495}, {'x': 550, 'y': 270}, {'x': 175, 'y': 120}],
			[{'x': 200, 'y': 220}, {'x': 250, 'y': 470}, {'x': 475, 'y': 120}, {'x': 775, 'y': 220}, {'x': 500, 'y': 320}, {'x': 175, 'y': 345}, {'x': 325, 'y': 145}, {'x': 50, 'y': 520}, {'x': 625, 'y': 570}, {'x': 50, 'y': 220}, {'x': 625, 'y': 445}, {'x': 375, 'y': 295}, {'x': 750, 'y': 420}, {'x': 375, 'y': 445}, {'x': 600, 'y': 195}, {'x': 850, 'y': 570}],
			[{'x': 50, 'y': 570}, {'x': 400, 'y': 545}, {'x': 250, 'y': 445}, {'x': 625, 'y': 345}, {'x': 850, 'y': 445}, {'x': 425, 'y': 320}, {'x': 600, 'y': 170}, {'x': 775, 'y': 195}, {'x': 150, 'y': 295}, {'x': 725, 'y': 520}, {'x': 275, 'y': 570}, {'x': 525, 'y': 545}, {'x': 475, 'y': 195}, {'x': 775, 'y': 320}, {'x': 100, 'y': 120}, {'x': 350, 'y': 170}],
			[{'x': 400, 'y': 170}, {'x': 400, 'y': 470}, {'x': 525, 'y': 470}, {'x': 825, 'y': 195}, {'x': 700, 'y': 195}, {'x': 275, 'y': 445}, {'x': 650, 'y': 370}, {'x': 750, 'y': 545}, {'x': 150, 'y': 495}, {'x': 250, 'y': 145}, {'x': 775, 'y': 370}, {'x': 100, 'y': 320}, {'x': 525, 'y': 295}, {'x': 100, 'y': 120}, {'x': 300, 'y': 295}, {'x': 575, 'y': 145}],
			[{'x': 475, 'y': 395}, {'x': 725, 'y': 395}, {'x': 475, 'y': 545}, {'x': 300, 'y': 170}, {'x': 150, 'y': 520}, {'x': 100, 'y': 120}, {'x': 300, 'y': 470}, {'x': 650, 'y': 145}, {'x': 450, 'y': 270}, {'x': 825, 'y': 545}, {'x': 50, 'y': 370}, {'x': 600, 'y': 295}, {'x': 700, 'y': 520}, {'x': 775, 'y': 220}, {'x': 175, 'y': 245}, {'x': 450, 'y': 120}],
			[{'x': 125, 'y': 395}, {'x': 650, 'y': 145}, {'x': 450, 'y': 495}, {'x': 300, 'y': 520}, {'x': 800, 'y': 545}, {'x': 75, 'y': 270}, {'x': 200, 'y': 245}, {'x': 325, 'y': 195}, {'x': 850, 'y': 420}, {'x': 650, 'y': 270}, {'x': 675, 'y': 470}, {'x': 350, 'y': 345}, {'x': 475, 'y': 320}, {'x': 825, 'y': 270}, {'x': 50, 'y': 520}, {'x': 50, 'y': 145}],
		]
		confs.sort(() => Math.random() - 0.5);
		let conf16 = confs[1]
		let cadConfClone = Object.assign({}, confs[1]);

		for (let i = 0; i < conf16.length; i++) {
			conf16[i].isClicked = false
			conf16[i].button_type = null
			conf16[i].backgroundColor = 'blue'
		}

		// Send the confirmations to the data
		this.data.push({cadConf: cadConfClone})
		return {conf: conf16}
	}

	// THE TIMER THAT RUNS FOR AN ENTIRE STAGE OF THE TEST
	stageTimer(stage) {
		if(stage === 1){
			this.stageInterval = setInterval(() => {
				const { stage1Seconds } = this.state
				if(stage1Seconds > 0){
					this.setState(({ stage1Seconds }) => ({
						stage1Seconds: stage1Seconds - 1
					}))
				}
				if(stage1Seconds === 0){
					clearInterval(this.stageInterval)
				}
			}, 10)
		}
		if(stage === 3){
			this.stageInterval = setInterval(() => {
				const { stage3Seconds } = this.state
				if(stage3Seconds > 0){
					this.setState(({ stage3Seconds }) => ({
						stage3Seconds: stage3Seconds - 1
					}))
				}
				if(stage3Seconds === 0){
					clearInterval(this.stageInterval)
				}
			}, 10)
		}
	}

	// TIMER THAT RUNS FOR EACH TESTING ROUND INSIDE OF A STAGE
	roundTimer() {
		this.roundStartTime = Date.now()
		this.roundInterval = setInterval(() => {
				const { currentStage, roundSeconds, stage1Seconds, stage3Seconds } = this.state
				if(roundSeconds > 0){
					this.setState(({ roundSeconds }) => ({
						roundSeconds: roundSeconds - 1
					}))
				} else {
					clearInterval(this.roundInterval)
					if((currentStage === 1 && stage1Seconds === 0)
						|| (currentStage === 3 && stage3Seconds === 0)) {
						// Turn off the levelCombo since the round wasn't completed
						this.setState({ levelCombo: 0 })
						this.roundEnd()
						this.stageEnd()
					} else {
						this.setState(({ roundSeconds }) => ({
							roundSeconds: 400
						}))
						this.roundEnd()
						this.roundStart()
					}
				}
		}, 10)
	}

	// THE CLICK ON EVERY BUTTON CLICK
	boxClick(value, index) {
		const { boxes, animateTime, animateItemTime } = this.state
		if (!boxes[index].isClicked && (animateTime === 0 || animateItemTime === 0)) {
			const updatedBox = {
				...boxes[index],
				isClicked: true,
				backgroundColor: 'gray',
				time: (Date.now() - this.roundStartTime) / 1000
			}
			this.clicks.push(updatedBox)
			boxes[index] = updatedBox
			this.setState({ boxes: boxes })
			this.checkCorrect(updatedBox)
		}
	}

	// USED TO SHOW INSTRUCTIONS BEFORE THE DIFFERENT STAGES OF TESTING
	toggleInstructions = () => {
		const { showInstructions } = this.state
		this.setState({showInstructions: !showInstructions})
		this.stageStart()

	}

	// CHECKS TO SEE IF THE SELECTED BOX WAS CORRECT, IF SO ADD MORE TIME TO THE CURRENT ROUND.
	// IF IT IS THE FINAL CORRECT ANSWER FOR THE ROUND THEN END THE ROUND EARLY
	checkCorrect(updatedBox) {
		const {roundSeconds, currentStage, correctItem, levels} = this.state
		const { boxes } = this.state
		let { topDogs } = this.state
		if(correctItem === updatedBox.button_type) {
			updatedBox.correct = true
			this.playSound()
			if((roundSeconds + this.clickIntervalTime) <= this.roundSeconds){
				this.setState(({ roundSeconds }) => ({
					roundSeconds: roundSeconds + this.clickIntervalTime
				}))
			}
		}
		// IF THERE IS A CORRECT BOX UNCLICKED, RETURN
		for (let i = 0; i < boxes.length; i++) {
			if(boxes[i].button_type === correctItem && !boxes[i].isClicked){
				return
			}
		}

		// UPDATE THE TOP DOGS TO THE NEW DOGS
		if(currentStage === 1 && levels[levels.length - 1].dogs > topDogs){
			topDogs = levels[levels.length - 1].dogs
		}

		// END THE ROUND
		this.setState(({ roundSeconds }) => ({
			roundSeconds: 0,
			earlyStopping: true,
			topDogs: topDogs
		}))
	}


	// CALLED AT THE BEGINNING OF EACH ROUND
	roundStart() {
		// DEBUG CHECK THE LEVEL
		const { levels, topDogs } = this.state 
		const { roundSeconds } = this.state
		// reset round seconds
		this.setState(({ roundSeconds }) => ({
			roundSeconds: 400
		}))
		// Shuffle Dogs and Cats
		this.shuffleDogsandCats()
		// Animate
		this.animateBoxes()
		// Enable Buttons
	}

	// CALLED AT THE END OF EACH ROUND
	roundEnd() {
		const { earlyStopping, correctItem, levels, levelCombo } = this.state
		// Pass Data
		this.data.push({correctItem: correctItem, clicks: this.clicks, level: levels[levels.length - 1]})
		this.clicks = []
		//this.resetBoxes()
		this.createLevel(earlyStopping, levelCombo)
		this.resetBoxes()
		this.setState(({ animateTime }) => ({
			animateTime: 300,
			animateItemTime: 700,
			animate: true,
			earlyStopping: false,
		}))
	}	

	// CREATE THE LEVEL BASED ON LEVELCOMBO AND EARLY STOPPING
	createLevel(earlyStopping) {
		const { levels, currentStage, levelCombo } = this.state
		const currentLevel = levels[levels.length - 1]
		if(currentStage === 1){
			if(earlyStopping) {
				if(currentLevel.dogs === 5){
					levels.push({'dogs': currentLevel.dogs + 1, 'cats': currentLevel.cats})
					this.setState({levelCombo: 0})
				} else if(currentLevel.dogs + levelCombo + 1 <= 8){
					levels.push({'dogs': currentLevel.dogs + levelCombo + 1, 'cats': currentLevel.cats})
				} else {
					levels.push({'dogs': 8, 'cats': currentLevel.cats})
				}
			} else if (currentLevel.dogs > 1){
				levels.push({'dogs': currentLevel.dogs - 1, 'cats': currentLevel.cats})
			} else {
				levels.push(currentLevel)
			}
		}
		if(currentStage === 3){
			if(earlyStopping && currentLevel.dogs < 8) {
				levels.push({'dogs': currentLevel.dogs + 1, 'cats': currentLevel.cats + 1 })
			} else if (currentLevel.dogs > 1){
				levels.push({'dogs': currentLevel.dogs - 1, 'cats': currentLevel.cats - 1 })
			} else {
				levels.push(currentLevel)
			}
		}
	}

	// RESETS THE BOXES BACK TO THEIR ORIGINAL STATE
	resetBoxes() {
		const { currentStage, levels} = this.state
		let resetBoxes = null
		// Pick the correct confirmation
		if(currentStage !== 0 && currentStage !== 4){
			if(levels[levels.length - 1].dogs > 5){
				resetBoxes = this.confirmation16
			} else {
				resetBoxes = this.confirmation10
			}
			for(let i = 0; i < resetBoxes.length; i++){
				resetBoxes[i].isClicked = false
				resetBoxes[i].button_type = null
				resetBoxes[i].backgroundColor = 'blue'
				resetBoxes[i].correct = false
				delete resetBoxes[i].clickTime
			}
		}
		this.setState({boxes: resetBoxes})
	}

	animateBoxes() {
		this.animateInterval = setInterval(() => {
			const { animateTime, animate } = this.state
			if(animateTime > 0){
				this.setState(({ animateTime }) => ({
					animateTime: animateTime - 1
				}))
			}
			if(animateTime === 0){
				clearInterval(this.animateInterval)
				this.setState({ animate: false })
				this.roundTimer()
			}
		}, 10)
	}

	// CALLED AT THE BEGINNING OF A STAGE
	stageStart() {
		const { currentStage, levels, topDogs} = this.state
		// CREATE THE SPECIAL LEVEL USING TopDogs
		if(currentStage === 3) {
			if(topDogs === 1){
				levels.push({'dogs': 1, 'cats': 1})
			} else {
				levels.push({'dogs': topDogs - 1, 'cats': topDogs - 1})
			}
		}
		this.stageTimer(currentStage)
		this.roundStart() 
	}

	// CALLED AT THE END OF A STAGE
	stageEnd() {
		const { showInstructions, currentStage, itemTypes } = this.state
		if (currentStage === 1) {
			this.setState({ 
				showInstructions: !showInstructions,
				currentStage: 3,
				correctItem: 'cat'
			})
		} else {
			this.setState({ catsAndDogsComplete: true })
		}
		// reset the boxes just incase
		// Reset Boxes
		this.resetBoxes()
	}

	toggle = () => {
        this.props.cadNoMemComplete(this.data)
		this.setState({clearModal: false})
	}

	// RANDOMLY SHUFFLES THE BOXES SO ANIMALS ARE ASSIGNED TO RANDOM LOCATIONS
	shuffleDogsandCats() {
		const { levels, boxes } = this.state
		const dogs = levels[levels.length - 1].dogs
		const cats = levels[levels.length - 1].cats
		const shuffled = boxes.sort(() => Math.random() - 0.5);
		for (let i = 0; i < dogs; i++){
			shuffled[i].button_type = 'dog';
		}
		for (let i = dogs; i < dogs+cats; i++){
			shuffled[i].button_type = 'cat';
		}
	}

	playSound() {
		const correctAudio = new Audio(dfy)
		correctAudio.play();
	}

	renderBox(value) {
		let imgsrc = null
		if(value.button_type === 'dog'){
			imgsrc = dog
		} else if (value.button_type === 'cat'){
			imgsrc = cat
		}
		return(
			<img src={imgsrc} alt="" width={100} height={100} style={{
				position: 'absolute',
				width: '100px',
				height: '100px',
				top: value.y,
				left: value.x,
				borderStyle: 'solid',
				borderColor: value.backgroundColor,
				borderWidth: '2px'}}
			/>)
	}

	renderCompleteBox(value) {
		let imgsrc = null
		return(
			<img src={imgsrc} alt="" width={100} height={100} 
				style={{
				position: 'absolute',
				width: '100px',
				height: '100px',
				top: value.y,
				left: value.x,
				borderStyle: 'solid',
				borderColor: value.backgroundColor,
				borderWidth: '2px'}}
			/>)
	}
	render() {
		const { showInstructions, currentStage, boxes, animate, clearModal, stage1Seconds, stage2Seconds, stage3Seconds, roundSeconds, animateTime, animateItemTime, catsAndDogsComplete, itemTypes, recallToggleFlag } = this.state
		return (
			<div>
				<div className="CatsAndDogs">
					{boxes.map((value, index) => 
						<div>
							{animateTime > 100 && this.renderBox(value)}
							{animateTime >= 280 && <div
								style={{
									position: 'absolute',
									width: '100px',
				  					height: '100px',
									top: value.y,
									left: value.x,
									backgroundColor: value.backgroundColor,
									opacity: (animateTime - 280)/20
								}}>
							</div>}
							{animateTime === 0 && !animate &&
								<div
								style={{
									position: 'absolute',
									width: '100px',
				  					height: '100px',
									top: value.y,
									left: value.x,
									backgroundColor: value.backgroundColor,
								}}
								onClick={() => this.boxClick(value, index)}>
							</div>}
							{animateTime === 0 && !animate && value.correct &&
								<img src={star3} alt="" width={100} height={100} 
									style={{
									position: 'absolute',
									width: '100px',
									height: '100px',
									top: value.y,
									left: value.x,
									backgroundColor: value.backgroundColor}} />}
						</div>
					)}
				</div>
				{!catsAndDogsComplete && showInstructions && currentStage === 1 &&
					<Modal centered={true} isOpen={showInstructions}>
				        <ModalBody className="text-center">When the squares turn blue, tap where the dogs were.
						</ModalBody>
				        <ModalFooter className="d-flex justify-content-center">
				          <Button color="primary" onClick={this.toggleInstructions}>Dismiss</Button>
				        </ModalFooter>
					</Modal>}
				{!catsAndDogsComplete && showInstructions && currentStage === 3 &&
					<Modal centered={true} isOpen={showInstructions}>
				        <ModalBody className="text-center">When the squares turn blue, tap where the cats were; do NOT tap where the dogs were.
						</ModalBody>
				        <ModalFooter className="d-flex justify-content-center">
				          <Button color="primary" onClick={this.toggleInstructions}>Dismiss</Button>
				        </ModalFooter>
					</Modal>}
				{catsAndDogsComplete &&
				 	<Modal centered={true} isOpen={clearModal} toggle={this.toggle}>
				        <ModalBody className="text-center">Test Complete!
						</ModalBody>
				   </Modal>}
				 {this.debugMode && <p> {stage1Seconds} {stage2Seconds} {stage3Seconds} {roundSeconds} {animateTime} {animateItemTime}</p>}
    		</div>
		);
	}
}

export default CatsAndDogsNoMemPractice;