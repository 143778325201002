import { AUTO_GROUP_ENTERED, AUTO_NAME_ENTERED, AUTO_AGE_ENTERED, AUTO_GENDER_ENTERED, AUTO_RACE_ENTERED, AUTO_YEARS_EDU_ENTERED, AUTO_DCOG_INCREMENT, AUTO_DCOG_SHORT_COMPLETED, AUTO_DCOG_LONG_COMPLETED } from '../actions/ActionTypes'

const initialState = {
	name: null,
	age: null,
	gender: null,
	race: null,
	yearsOfEdu: null,
	group: null,
	dcogRun: 1,
	dcogShort: [],
	dcogLong: []
}

export default function (state = initialState, action) {
	console.log(action)
	switch(action.type) {
		case AUTO_GROUP_ENTERED:
			return Object.assign({}, state, {
				group: action.data
			})
		case AUTO_NAME_ENTERED:
			return Object.assign({}, state, {
				name: action.data
			})
		case AUTO_AGE_ENTERED:
			return Object.assign({}, state, {
				age: action.data
			})
		case AUTO_GENDER_ENTERED:
			return Object.assign({}, state, {
				gender: action.data
			})
		case AUTO_RACE_ENTERED:
			return Object.assign({}, state, {
				race: action.data
			})
		case AUTO_YEARS_EDU_ENTERED:
			return Object.assign({}, state, {
				yearsOfEdu: action.data
			})
		case AUTO_DCOG_INCREMENT:
			return Object.assign({}, state, {
				dcogRun: state.dcogRun + 1
			})
		case AUTO_DCOG_SHORT_COMPLETED:
			return Object.assign({}, state, {
				dcogShort: state.dcogShort.concat(action.data)
			})
		case AUTO_DCOG_LONG_COMPLETED:
			return Object.assign({}, state, {
				dcogLong: state.dcogLong.concat(action.data)
			})
		default:
			return state
	}
}