import React from 'react'
import { Nav, NavItem, Button } from 'reactstrap';
import * as BSIcons from 'react-icons/bs';
import { Link } from 'react-router-dom';
import packageJson from '../package.json'
import './SideNavBar.css'
import routes from './routes'
//import store from './store'
import { Container } from 'reactstrap';

class SideNavBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sideBarOpen: true
		}
	}

	componentDidMount() {	
	} 

	toggleSideBar = () => {
		const { sideBarOpen } = this.state
		this.setState({sideBarOpen: !sideBarOpen})
	}

	hideSideBar = () => {
		this.setState({sideBarOpen: false})
	}

	render = () => {
		const { sideBarOpen } = this.state
		const { entryCode, testMode } = this.props
		return(			
			<Container fluid="true">
				<Nav vertical={true} className={sideBarOpen ? 'nav-menu active':'nav-menu'} style={{"background-color": "black"}}>
				<Button onClick={this.toggleSideBar} className="m-2" style={{'min-width': '50px'}}>
			      	{sideBarOpen ? <span> Hide <BSIcons.BsBoxArrowInLeft /> </span>: <BSIcons.BsBoxArrowInRight />}
			    </Button>
			    {sideBarOpen && <div>
				    <p className="text-center my-1 text-white"> iBoca3   v{packageJson.version}</p>
				    <p className="text-center my-1 text-white"> id: { entryCode } </p>
				    <p className="text-center my-1 text-white"> mode: { testMode } </p>
				    <div className="border border-light rounded my-2">
				    	<p className="text-center m-1 text-white"> Tests </p>
						{routes.test_routes.map((value, index) => {
							return(
 								<NavItem className='m-1'>
				    				<Link to={value.path}>
										<Button color="primary" block="true" onClick={this.hideSideBar}>{value.text}</Button>
									</Link>
				    			</NavItem>
							)
						})}
				    </div>
					{/*  ---- REMOVING PRACTICE BUTTON(S) FROM UI ----
				   <div className="border border-light rounded my-2">
				    	<p className="text-center m-1 text-white"> Practice </p>
						{routes.practice_routes.map((value, index) => {
							return(
 								<NavItem className='m-1'>
				    				<Link to={value.path}>
										<Button color="info" block="true"onClick={this.hideSideBar}>{value.text}</Button>
									</Link>
				    			</NavItem>
							)
						})}
				    </div>
					*/}
					<div className="my-3 mx-2">
					 	<NavItem>
				    		<Link to={routes.results.path}>
								<Button color="success" block="true">
									<div class="d-flex justify-content-between">
										{routes.results.text}
										<BSIcons.BsCardChecklist size="1.5em"/>
									</div>
								</Button>
							</Link>
				    	</NavItem>
				    </div>
					<div className="my-3 mx-2">
						<NavItem>
				    		<Link to={routes.senddata.path}>
				    			<Button color="success" block="true">
									<div class="d-flex justify-content-between">
										{routes.senddata.text}
										<BSIcons.BsCloudUpload size="1.5em"/>
									</div>
								</Button>
							</Link>
				    	</NavItem>
				    </div>
				    <div className="my-3 mx-2">
						<NavItem>
				    		<Link to={routes.questionaire.path}>
				    			<Button color="success" block="true">
									<div class="d-flex justify-content-between">
										{routes.questionaire.text}
										<BSIcons.BsCardChecklist size="1.5em"/>
									</div>
								</Button>
							</Link>
				    	</NavItem>
				    </div>
					<div className="my-3 mx-2">
						<NavItem>
				    		<Link to={routes.reset.path}>
				    			<Button color="success" block="true">
									<div class="d-flex justify-content-between">
										{routes.reset.text}
										<BSIcons.BsArrowCounterclockwise size="1.5em"/>
									</div>
								</Button>
							</Link>
				    	</NavItem>
				    </div>
				</div>}
			</Nav>
			</Container>
		)
	}
}

export default SideNavBar