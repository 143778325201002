import React from 'react';
import './App.css'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input} from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { autoNameEntered } from './actions/AUTOComplete'

class IntroConsentContainer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			name: null
		}
	}
	
	componentDidMount() {	
		this.targetElement = document.querySelector('.ButtonStrip');
		//disableBodyScroll(this.targetElement) //lock/prevent page scrolling so that the "game board" doesn't move when accidentally dragging the screen during testing
		document.body.ontouchmove = (e) => { e.preventDefault(); return false; }
	}
  	
	inputChange = (e) => {
    	this.setState({ name: e.target.value });
  	}

	render = () => {
	const { name } = this.state
	const { group, autoNameEntered } = this.props
		return(
			<Modal centered isOpen={true}>
				<ModalHeader>
					<h3> Introduction/Consent </h3>
				</ModalHeader>
	            <ModalBody className="text-center">
	                <p className="my-2"> Thank you for participating in this study. Our goal is to develop a new medical tool that can be used to detect and monitor delirium. The study will involve a few tasks and questionnaires. It should take approximately 20 minutes to complete. </p>
	            	<p className="my-2"> By typing your name below, you consent to participate in this study. You can stop at any time and for any reason. Thank you again for your time and your effort. </p>
	            	<Input placeholder="please type your name here"
	                     		className="text-center"
	                     		value={name}
	                     		onChange={this.inputChange}/>
	            </ModalBody>
	            <ModalFooter className="d-flex justify-content-center">
	            	<Link to={ group === 1 ? "/auto/d-cog-short" : "/auto/d-cog-long" }>
		                <Button 
		                	disabled={name === null || name === ""}
		                	color="primary"
		                	onClick={autoNameEntered(name)}
		                >Enter</Button>
	                </Link>
	            </ModalFooter>
	       </Modal>
		)
	}
}

function mapStateToProps(state) {
	return {
		group: state.auto.group
	}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ autoNameEntered: autoNameEntered }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(IntroConsentContainer);