import React from 'react'
import * as BSIcons from 'react-icons/bs';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Row, Col, Collapse} from 'reactstrap';
import { Link } from 'react-router-dom';
import store from './store'

class SendData extends React.Component {
	constructor(props) {
		super(props);
        this.state = { dataSent: false, showData: false}
	}
    sendData = {
		messageObject: null,
		statusCode: null,
		responseTime: 0,
		size: 0
	}
	dataStream = ''
	
	componentDidMount() {	
		const storeState = store.getState();
	  	const { entryCode, testID } = this.props
		this.setState({testFinished: false})
	  	let dat = null
		switch (testID) {
			case 'catsanddogs': 
				dat = {catsanddogs: storeState.catsanddogs}; break;
			case 'catsanddogsnomem': 
				dat = {catsanddogsnomem: storeState.catsanddogsnomem}; break;
			case 'ccatsanddogsnomemfeb19': 
				dat = {catsanddogsnomemfeb19: storeState.catsanddogsnomemfeb19}; break;
			case 'cpt': 
				dat = {cpt: storeState.cpt}; break;
			case 'tmt': 
				dat = {tmt: storeState.tmt}; break;
			default: 
				dat = storeState;
		}
	  	this.dataStream = JSON.stringify({ 
			name: entryCode + '-' + testID + '-' + this.formattedDate(),
		    data: { 'platform': window.navigator.userAgent, ...dat }
		  })
	  	const sendStartTime = Date.now()
	  	return fetch('https://0dmiwnt6r8.execute-api.us-east-1.amazonaws.com/default/iBoca3Upload', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json'},
			body: this.dataStream
		}).then( result => this.processMessage(result, Date.now()-sendStartTime, this.dataStream.length) )
  	}
	
	formattedDate() {
		function fillzero (s) {return (s.length < 2) ? ('0' + s) : s}
		const d = new Date()
		const year = d.getFullYear().toString()
		const month = (d.getMonth() + 1).toString()
		const date = d.getDate().toString()
		const hour = d.getHours().toString()
		const minute = d.getMinutes().toString()		
		return year + '-' + fillzero(month) + fillzero(date) + '-' + fillzero(hour) + fillzero(minute) 
	}


  	renderResponseHeader = (statusCode, responseTime) => {
  		if (200 <= statusCode <= 299){
  			return(
  				<ModalHeader className="d-flex justify-content-center badge-success">
					{this.props.header ? 'Test and Upload Complete' : 'Upload Complete'}
					<BSIcons.BsCheck size="1.5em" className="mx-2"/>
					<span class="badge badge-secondary ml-5">{responseTime} ms</span>
				</ModalHeader>
  			)
  		} else if (400 <= statusCode <= 499){
  			return(
  				<ModalHeader className="d-flex justify-content-center badge-danger">
					Upload Client Error
  					<BSIcons.BsExclamationCircleFill size="1.5em" className="mx-2"/>
  					<span class="badge badge-secondary ml-5">{responseTime} ms</span>
				</ModalHeader>
  			)
  		} else if (500 <= statusCode <= 599){
  			return(
  				<ModalHeader className="d-flex justify-content-center badge-danger">
					Upload Server Error
  					<BSIcons.BsExclamationCircleFill size="1.5em" className="mx-2"/>
  					<span class="badge badge-secondary ml-5">{responseTime} ms</span>
				</ModalHeader>
  			)
  		} else {
  			return(
  				<ModalHeader className="d-flex justify-content-center badge-danger">
					Unkown Upload Error
  					<BSIcons.BsExclamationCircleFill size="1.5em" className="mx-2"/>
  					<span class="badge badge-secondary ml-5">{responseTime} ms</span>
				</ModalHeader>
  			)
  		}
  	}

	processMessage = (x,responseTime,dataSize) => {
		this.sendData = {
			messageObject: x,
			statusCode: x.status,
			responseTime: responseTime,
			size: dataSize
		}
		this.setState({showSendReport: true})
	}
	showDataToggle = () => { this.setState({showData: !this.state.showData}) }

	render = () => {
		const { entryCode, testID, animate} = this.props
		const {sendData, showDataToggle} = this
		const {showData} = this.state
		
		return(
			<Modal centered={true} isOpen={true} size={ showData ? 'xl' : null } fade={animate}>
				{this.renderResponseHeader(sendData.statusCode, sendData.responseTime)}
		        <ModalBody>
		        	<Row className="d-flex">
		        		<Col> 
		        			<div className="d-flex flex-row justify-content-center">
		        				<b className="m-1">ID:</b><p className="m-1">{entryCode}</p>
		        			</div>
		        		</Col>
						<Col> 
		        			<div className="d-flex flex-row justify-content-center">
		        				<b className="m-1">Test(s) :</b><p className="m-1">{testID}</p>
		        			</div>
		        		</Col>
		        	</Row>
		        	<Row className="d-flex">
		        		<Col> 
		        			<div className="d-flex flex-row justify-content-center">
		        				<b className="m-1">Data Size :</b><p className="m-1">{sendData.size} chars </p>
		        			</div>
		        		</Col>
						<Col> 
		        			<div className="d-flex flex-row justify-content-center">
		        				<Button color="secondary" block="true" onClick={showDataToggle}>
		        					{showData ? "Hide Data" : " View Data"}
		        					{showData ? <BSIcons.BsFillCaretUpFill size="1.5em" className="mx-2"/> : <BSIcons.BsFillCaretDownFill size="1.5em" className="mx-2"/>}
		        				</Button>
		        			</div>
		        		</Col>
		        	</Row>
		        	<Collapse isOpen={showData}>
		        		<div className="bg-light">
		        			<code>{this.dataStream}</code>
		        		</div>
		        	</Collapse>
		        </ModalBody>
		        <ModalFooter className="d-flex justify-content-center">
				 	<Link to='/'>
					  	<Button color="primary">OK</Button>
					</Link>
				</ModalFooter>
		    </Modal>
		)
	}
}

export default SendData