import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { autoAgeEntered, autoGenderEntered, autoRaceEntered, autoYearsEduEntered } from './actions/AUTOComplete'
import { bindActionCreators } from 'redux';

class Demographics extends React.Component {

	componentDidMount() {	
		this.targetElement = document.querySelector('.ButtonStrip');
		//disableBodyScroll(this.targetElement) //lock/prevent page scrolling so that the "game board" doesn't move when accidentally dragging the screen during testing
		document.body.ontouchmove = (e) => { e.preventDefault(); return false; }
	}

	constructor(props) {
		super(props);
		this.state = {
			genderDropdownOpen: false,
			raceDropdownOpen: false,
			gender: null,
			race: null,
			age: null,
			yearsOfEdu: null
		}
	}

	toggleGenderDropdown = () => {
		this.setState({ genderDropdownOpen: !this.state.genderDropdownOpen, raceDropdownOpen: false})
	}

	toggleRaceDropdown = () => {
		this.setState({ raceDropdownOpen: !this.state.raceDropdownOpen, genderDropdownOpen: false})
	}

	toggleGender = (gender) => {
		this.setState({ gender: gender })
	}

	toggleRace = (race) => {
		this.setState({ race: race })
	}

	ageChange = (event) => {
		this.setState({ age: event.target.value })
	}

	yearsofEduChange = (event) => {
		this.setState({ yearsOfEdu: event.target.value })
	}

	sendDemographics = () => {
		const { age, race, gender, yearsOfEdu } = this.state
		const { autoAgeEntered, autoRaceEntered, autoGenderEntered, autoYearsEduEntered } = this.props
		autoAgeEntered(age)
		autoRaceEntered(race)
		autoGenderEntered(gender)
		autoYearsEduEntered(yearsOfEdu)
	}

	render = () => {
		const { genderDropdownOpen, raceDropdownOpen, gender, race, age, yearsOfEdu } = this.state
		const { group } = this.props
		return(
			<Modal centered isOpen={true}>
				<ModalHeader className="text-center">
					<p>Demographics</p>
				</ModalHeader>
	            <ModalBody>
	            <InputGroup size="lg" className="my-2">
	            <InputGroupAddon addonType="prepend">Gender</InputGroupAddon>
			      <Dropdown size="lg" isOpen={genderDropdownOpen} toggle={this.toggleGenderDropdown}>
			        <DropdownToggle color="primary" caret>
			          {gender !== null ? gender : "Select Gender"}
			        </DropdownToggle>
			        <DropdownMenu>
			          <DropdownItem onClick={() => this.toggleGender("Male")}>Male</DropdownItem>
			          <DropdownItem onClick={() => this.toggleGender("Female")}>Female</DropdownItem>
			          <DropdownItem onClick={() => this.toggleGender("Other")}>Other</DropdownItem>
			        </DropdownMenu>
			      </Dropdown>
			    </InputGroup>
	            <InputGroup size="lg" className="my-2">
			   	<InputGroupAddon addonType="prepend">Race</InputGroupAddon>
			      <Dropdown size="lg" isOpen={raceDropdownOpen} toggle={this.toggleRaceDropdown}>
			        <DropdownToggle color="primary" caret>
			          {race !== null ? race : "Select Race"}
			        </DropdownToggle>
			        <DropdownMenu>
			          <DropdownItem onClick={() => this.toggleRace("White")}>White</DropdownItem>
					  <DropdownItem onClick={() => this.toggleRace("Black or African American")}>Black or African American</DropdownItem>
					  <DropdownItem onClick={() => this.toggleRace("American Indian or Alaska Native")}>American Indian or Alaska Native</DropdownItem>
					  <DropdownItem onClick={() => this.toggleRace("Asian")}>Asian</DropdownItem>
			          <DropdownItem onClick={() => this.toggleRace("Native Hawaiian or Other Pacific Islander")}>Native Hawaii or Other Pacific Islander</DropdownItem>
			        </DropdownMenu>
			      </Dropdown>
			    </InputGroup>
			    <InputGroup size="lg" className="my-2">
					<InputGroupAddon addonType="prepend">Age</InputGroupAddon>
					<Input placeholder="Enter Age" onChange={this.ageChange} />
			    </InputGroup>
			    <InputGroup size="lg" className="my-2">
					<InputGroupAddon addonType="prepend">Years of Education</InputGroupAddon>
					<Input placeholder="Enter Years of Education" onChange={this.yearsofEduChange} />
			    </InputGroup>
	            </ModalBody>
	            <ModalFooter className="d-flex justify-content-center">
	            	<Link to={group === 1 ? '/auto/d-cog-long' : '/auto/d-cog-short'}>
		                <Button 
		                	disabled={gender === null || gender === "" 
		                	|| race === null || race === "" 
		                	|| age === null || age === "" 
		                	|| yearsOfEdu === null || yearsOfEdu === ""}
		                	color="primary"
							onClick={this.sendDemographics()}
		                >Continue</Button>
	                </Link>
	            </ModalFooter>
	       </Modal>
		)
	}
}

function mapStateToProps(state) {
	return {
		group: state.auto.group
	}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
		autoGenderEntered: autoGenderEntered,
		autoRaceEntered: autoRaceEntered,
		autoAgeEntered: autoAgeEntered,
		autoYearsEduEntered: autoYearsEduEntered,
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Demographics);