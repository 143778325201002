import React from 'react';
import sndDone from '../audio/coinflip190m.wav';
import sndCorrect from '../audio/ding240m.wav';
import sndIncorrect from '../audio/incorrect100m.wav';
import instruct1 from '../audio/instructions2_cpt_michael_default.mp3';

import '../../../App.css'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cptComplete } from '../../../actions/CPTComplete'
import PatientQuestionnaire from '../../../PatientQuestionnaire'

class CPT extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showInstructions: true,
            cptComplete: false,
            blank: true,
            instructAudioFinished: false
        }
    }

    static totalTime = 130
    stimNum = -1
    minClickInterval = 200     // Any click (onTouch/onMouseDown) messages within 200ms of the last accepted message will be discarded
    maxGoodRT = 1200           // Any response within 1200ms of display onset is considered "good" and receives a chime sound effect
    blankDurations = [10, 35, 60, 85, 110]                          // possible blanking intervals for last 2/3s of stimuli
    blankDuration0 = (this.props.testMode==='Demo') ? 20 : 50       // blanking interval for 1st third of stimuli (after that is gets randomized)
           numStim = (this.props.testMode==='Demo') ? 30 : 90       // Make this a multiple of 3 becuase the stimulus list (letter array) should have 3 equal-length segments
    letterDisplayTicks = (this.props.testMode==='Demo') ? 40 : 100  // stimulus (letter) display interval for all stimuli
    instructTicks = 170       // voice instructions are about 1.7sec long (latency unit start button enabled)
    timerInterval = 10

    letterList = this.pickStimulusList(this.numStim)
    tX = []
    nX = 0
    nClick = []
    nClickTot = 0
    nClickGood = 0
    nClickLate = 0

    stims = []   //stimulus presentation data to be saved
	clicks = []  //click data to be saved
    data = [{ ver: 'CPT JS v1.05 (3/30/21)', date: Date() }]  //First element of the data array has task version info (title, version, revdate) and test date/time
    myAudio = this.setupWebAudio()
    instructAudioStarted = false
    testOnsetTime = Date.now()
	
    pickStimulusList(N) {
        const firstStageStrings = [
            ["S","O","T","G","X","I","Q","S","L","A","X","I","Y","D","X","S","A","F","E","X","K","T","L","X","S","I","Z","X","E","Z"],
            ["I","S","R","X","U","A","T","H","X","N","F","Q","Y","S","X","A","O","B","X","L","S","I","Y","Z","D","X","M","I","X","Q"],
            ["K","X","J","U","S","X","V","O","W","R","X","Q","X","Z","L","J","V","D","H","X","K","Y","B","R","C","J","X","Z","Q","M"],
            ["G","X","I","E","F","U","Z","X","P","A","L","G","X","Z","L","D","X","L","K","P","J","L","Q","X","M","P","O","X","A","I"]
        ]
        const lastStageStrings = [
            ["S","c","P","B","X","O","z","a","X","b","C","w","F","a","X","m","l","A","X","F","P","N","M","B","X","K","l","q","X","g"],
            ["W","I","m","X","q","E","H","X","q","X","H","l","B","u","X","q","g","J","r","v","X","E","v","A","J","r","X","v","q","S"],
            ["Y","u","X","g","W","c","Q","X","p","S","U","F","Q","X","U","b","s","W","Y","X","j","a","r","X","S","f","c","N","X","P"],
            ["q","i","D","A","X","l","K","S","D","p","X","W","c","X","Z","m","N","X","n","K","D","K","g","e","X","N","k","G","X","c"]
        ]
        const shuffledFirstStrings = firstStageStrings.sort(() => Math.random() - 0.5) //randomly shuffle the ALL_CAPS array
        const shuffledLastStrings = lastStageStrings.sort(() => Math.random() - 0.5)  //randomly shuffle the mixed-case array
        let strings = [];
        strings = strings.concat( this.random5050() ? shuffledFirstStrings[0] : shuffledFirstStrings[0].reverse()) //reverse the 1st-third character string half the time
        strings = strings.slice(0,N/3)
        strings = strings.concat( this.random5050() ? shuffledFirstStrings[1] : shuffledFirstStrings[1].reverse()) //reverse the 2ns-third character string half the time
        strings = strings.slice(0,2*N/3)
        strings = strings.concat( this.random5050() ? shuffledLastStrings[0] : shuffledLastStrings[0].reverse())   //reverse the 3rd-third character string half the time
        strings = strings.slice(0,N)
        return strings
    }


	// SETUP for using Web Audio API: defining an AudioContext, and loading a buffer with decoded raw sound data
	setupWebAudio() {
		const sndList = [sndCorrect, sndIncorrect, sndDone] //, instruct1, instruct3] 
		const myAudio = {context: null, buffer: [], bufferLoaded: [], iCorrect: 0, iIncorrect: 1, iEndRound: 2, iInstructA: 3, iInstructB: 4 }	
		window.AudioContext = window.AudioContext||window.webkitAudioContext;
		myAudio.context = new AudioContext();//myAudio.context = new AudioContext(window.AudioContext || window.webkitAudioContext);
		for (let i = 0; i < sndList.length; i++) {
			myAudio.bufferLoaded[i]=false                   //initialization allowing us to check beffer length later on
			const request = new XMLHttpRequest();
			request.open('GET', sndList[i], true);
			request.responseType = 'arraybuffer';
			request.onload = function() {
				myAudio.context.decodeAudioData(request.response, function(theBuffer) {        // Decode asynchronously
				myAudio.buffer[i] = theBuffer;
				myAudio.bufferLoaded[i]=true                   //initialization allowing us to check beffer length later on
	  			}, e => console.log('sound load message: [' + i + '] ' + e) );
			}
			request.send();
		}
		return myAudio
	}

	playWebAudio(bufferIndex) {
		const source = this.myAudio.context.createBufferSource()
		source.buffer = this.myAudio.buffer[bufferIndex]
		source.connect(this.myAudio.context.destination)
		source.start(0)
	}

    begin = () => {
        this.instructAudioStarted = false
		this.setState({ 
			instructAudioFinished: false,
			showInstructions: false
		})
        this.startTime = Date.now()
        this.blankDisplayTimer()
    }

    blankDisplayTimer = () => {         //timer for blank intervals between letter stimuli
        this.stimNum++
        const { stimNum, blankDurations, blankDuration0 } = this
        this.setState({blank: true})
        let blankTicks = (stimNum <= 29) ? blankDuration0 : blankDurations[Math.floor(Math.random() * blankDurations.length)]
        this.animationInterval = setInterval(() => {
            blankTicks--
            if(blankTicks <= 0){
                clearInterval(this.animationInterval)
                this.letterDisplayTimer()
            }
        }, this.timerInterval)
    } 

    letterDisplayTimer = () => {               //timer for letter stimuli display
        const { stimNum, letterList } = this
        this.setState({ blank: false })
        this.lastStimTime = Date.now()
        const stim = this.letterList[stimNum]
        this.lastXTime = (stim === 'X') ? this.lastStimTime : this.lastXTime
        this.nX   += (stim === 'X') ? 1 : 0
        this.stims.push({ stim: stim,  tStim: this.lastStimTime - this.startTime})
        let ticks = this.letterDisplayTicks
        this.cptInterval = setInterval(() => {
            ticks--
            if(ticks <= 0){
                clearInterval(this.cptInterval)
                if (stimNum < letterList.length){
                    this.blankDisplayTimer()    //timer for blank intervals between letter stimuli
                } else {
                    this.computeSummaryStats()
                    this.setState({ cptComplete: true })
                }
            }
        }, this.timerInterval)
    }

    computeSummaryStats() {
        let nMissed = 0
        let nGoodRT = 0
        let nLateRT = 0
        let goodRT1 = 0
        const goodRT = []
        for (let i = 0; i < this.nX; i++) {
            if (this.nClick[i] > 0) {
                goodRT1 = (this.tX[i][0] <= this.maxGoodRT) ? 1 : 0
                if (goodRT1) {
                    goodRT.push( (this.tX[i][0] <= this.maxGoodRT) ? this.tX[i][0] : [] )
                    nGoodRT++
                } else {
                    nLateRT ++
                }
            } else {
                nMissed++
            }
        }
        const nExtra = this.nClickTot - (nGoodRT + nLateRT)
        const meanGoodRT = this.round(this.mean(goodRT),2)
        const medianGoodRT = this.median(goodRT)
        const actionTime = this.round((Date.now() - this.startTime)/1000,1)
        const instructTime = this.round((this.startTime - this.testOnsetTime)/1000,1)
        const testTime = this.round(actionTime + instructTime,1)
        this.data.push(this.stims)
        this.data.push(this.clicks)
        this.data.push({ meanGoodRT: meanGoodRT, medianGoodRT: medianGoodRT, nTgts: this.nX, missedTgts: nMissed, goodRTs: nGoodRT, lateRTs: nLateRT, extraRTs: nExtra, actionTime: actionTime, instructTime: instructTime, testDuration: testTime })
        console.log('Good RTs: ' + goodRT)    
    }                

    round(x,n){return Math.round(x*(10**n))/(10**n)}  //rounds x to the nth decimal place 

    median(x){
        let i = x.length / 2;
        x.sort(function(a, b){ return a - b; });
        return (i % 1 === 0) ? (x[i - 1] + x[i]) / 2 : x[Math.floor(i)];
    }
    
    mean(x){
        let y = 0
        for (let i = 0; i < x.length; i++) { y += x[i]/x.length }
        return y
    }
      
    componentWillUnmount() {
        clearInterval(this.cptInterval)
        clearInterval(this.animationInterval)
    }

    random5050() {
        return (Math.floor(Math.random() * 2) === 0)
    }

    cptClick = (clickType) => {
        const { stimNum, letterList, maxGoodRT, nX } = this
        const t = Date.now()
        const lastClickTime = (this.clicks.length === 0) ? null : this.clicks[this.clicks.length-1].t + this.startTime 
        const acceptedClick = !(t - lastClickTime < this.minClickInterval)
        const tX = t - this.lastXTime;
        if (acceptedClick) { 
            if (t - this.lastXTime <= maxGoodRT) {
                this.playWebAudio(this.myAudio.iCorrect)
                this.nClickGood++
            } else {
                this.playWebAudio(this.myAudio.iIncorrect)
                this.nClickLate++
            }
            const click = {clickType: clickType, stim: letterList[stimNum], tStim: t - this.lastStimTime, tX: tX, t: t - this.startTime }
            this.clicks.push(click)
            this.nClickTot++
            if (nX > 0) {
                this.nClick[nX-1] = (this.nClick.length < nX) ? 0 : this.nClick[nX-1]  //initialization if needed
                this.nClick[nX-1]++
                this.tX[nX-1] = (this.tX.length < nX) ? [] : this.tX[nX-1]    //initialization if needed
                this.tX[nX-1].push(tX)
            }
        } 
    }

    playSound(snd) {
        const correctAudio = new Audio(snd)
        correctAudio.play()
    }

    speakInstructions = () => {
        if (!this.instructAudioStarted) {
            this.playSound(instruct1)
            this.instructStartTime = Date.now()
            this.instructAudioStarted = true  // Note: cannot use a state var with setstate() for this, as it wouldn't update immediately!!
			let ticks = this.instructTicks
			this.instructInterval = setInterval(() => {  // TIMER THAT RUNS TO ENABLE START BUTTON AFTER VOICE INSTRUCTIONS FINISHED
				ticks--
				if (ticks <= 0){
						clearInterval(this.instructInterval)
						this.setState({ instructAudioFinished: true })
					}
			}, this.timerInterval)
        }
        return true
    }

    render() {
        const { showInstructions, blank, cptComplete, instructAudioFinished } = this.state
        const { entryCode } = this.props
        const { stimNum, letterList } = this
        const letter = blank ? ' ' : letterList[stimNum]
        return (
            <div>
                <div className="CatsAndDogs">
                    {!showInstructions &&
                        <div style={{
                                position: 'absolute',
                                width: '500px',
                                height: '500px',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: 'lightgray',
                                textAlign: 'center',
                                verticalAlign: 'middle',
                                fontSize: '200px'
                            }}
                            className="d-flex justify-content-center align-items-center"
                            //onClick={() => this.cptClick()}
                            onTouchStart={() => this.cptClick(1)}
                            onMouseDown={() => this.cptClick(2)}>
		
                            <p>{letter}</p>
                        </div>
                    }
                </div>
                {showInstructions && this.speakInstructions() &&
                <Modal centered={true} isOpen={showInstructions}>
                    <ModalBody className="text-center">Tap as soon as you see each X
                    </ModalBody>
                    <ModalFooter className="d-flex justify-content-center">
                        <Button disabled={!instructAudioFinished} color="primary" onClick={this.begin}>Start</Button>
                    </ModalFooter>
                </Modal>}
                {cptComplete && this.props.testDone()}
                {cptComplete && this.props.cptComplete(this.data) && <PatientQuestionnaire entryCode={entryCode} testID='cpt'/>}
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ cptComplete: cptComplete}, dispatch)
}

export default connect(null, mapDispatchToProps)(CPT);