import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import { MdMoodBad, MdSentimentDissatisfied, MdSentimentNeutral, MdSentimentSatisfied, MdMood } from "react-icons/md";
import { Link } from 'react-router-dom';
import SendData from './SendData';

class PatientQuestionaire extends React.Component {
	constructor(props) {
		super(props);
        this.state = { 
        	q1: null,
        	q2: null,
        	modalFocus: true
        }
	}

	setQuestion = (q, key) => {
		this.setState({[q]: key})
	}

	inputChange = (e) => {
    	this.setState({ text: e.target.value });
  	}

	formattedDate() {
		function fillzero (s) {return (s.length < 2) ? ('0' + s) : s}
		const d = new Date()
		const year = d.getFullYear().toString()
		const month = (d.getMonth() + 1).toString()
		const date = d.getDate().toString()
		const hour = d.getHours().toString()
		const minute = d.getMinutes().toString()		
		return year + '-' + fillzero(month) + fillzero(date) + '-' + fillzero(hour) + fillzero(minute) 
	}

	questions = [
		{'q_num' : 'q1', 'q_text' : 'How clear were the instructions?', 'high': 'very easy', 'low': 'very difficult'},
		{'q_num' : 'q2', 'q_text' : 'How happy would you be to do this again?', 'high': 'very happy', 'low' : 'not happy at all'},
	]

	sendData = () => {
		const { entryCode, testID } = this.props
		const data = JSON.stringify({
			'name': entryCode + '-' + testID + '-questionnaire-' + this.formattedDate(), 
			'data': {'questions' : this.questions, ...this.state}
		})
		return fetch('https://0dmiwnt6r8.execute-api.us-east-1.amazonaws.com/default/iBoca3Upload', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json'},
			body: data
		}).then(result => console.log(result))
	}

	changeFocus = () => {
		this.setState({
			modalFocus: false
		})
	}

	render = () => {
		const { modalFocus } = this.state
		const { entryCode, testID } = this.props

		const styleGreat = {
			color: "#44ff00"
		}
		const styleGood = {
			color: "#b3ff00"
		}
		const styleNeutral = {
			color: "#fff700"
		}
		const styleNotGood = {
			color: "#ff8400"
		}
		const styleBad = {
			color: "#ff0000"
		}

		if (modalFocus) {
		return(
			<Modal centered={true} isOpen={true} size={'lg'}>
				<ModalHeader className="d-flex justify-content-center">
					Questionnaire
				</ModalHeader>
		        <ModalBody>
		        	{this.questions.map((q_value, q_index) => {
						return(
							<>
							<Row className="d-flex m-3">
								<Col md="3">
									<b>{q_value.q_text}</b>
								</Col>
								<Col className="justify-content-center">
									<Button className="" onClick={() => this.setQuestion(q_value.q_num, 1)} active={this.state[q_value.q_num] === 1}>
										<MdMood size="4em" className="mx-2" style={styleGreat}/>
									</Button>
								</Col>
								<Col className="justify-content-center">
									<Button className="" onClick={() => this.setQuestion(q_value.q_num, 2)} active={this.state[q_value.q_num] === 2}>
										<MdSentimentSatisfied size="4em" className="mx-2" style={styleGood}/>
									</Button>
								</Col>
								<Col className="justify-content-center">
									<Button className="" onClick={() => this.setQuestion(q_value.q_num, 3)} active={this.state[q_value.q_num] === 3}>
										<MdSentimentNeutral size="4em" className="mx-2" style={styleNeutral}/>
									</Button>
								</Col>
								<Col className="justify-content-center">
									<Button className="" onClick={() => this.setQuestion(q_value.q_num, 4)} active={this.state[q_value.q_num] === 4}>
										<MdSentimentDissatisfied size="4em" className="mx-2" style={styleNotGood}/>
									</Button>
								</Col>
								<Col className="justify-content-center">
									<Button className="" onClick={() => this.setQuestion(q_value.q_num, 5)} active={this.state[q_value.q_num] === 5}>
										<MdMoodBad size="4em" className="mx-2" style={styleBad}/>
									</Button>
								</Col>
	     					 </Row>
	     					 <hr/>
	     					 </>
	     				)
		        	})}
		        </ModalBody>
		        <ModalFooter className="d-flex justify-content-center">
					<Link onClick={this.sendData}>
						<Button color="success" onClick={this.changeFocus}>Send</Button>
					</Link>
					<Link>
						<Button color="primary" onClick={this.changeFocus}>Close</Button>
					</Link>
				</ModalFooter>
		    </Modal>
		)
		} else {
			return(<SendData entryCode={entryCode} testID={testID} header='Test Complete!' animate={false}/>)
		}
	}
}

export default PatientQuestionaire