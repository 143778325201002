import React from 'react';
import sndDone from '../audio/coinflip190m.wav';
import sndCorrect from '../audio/ding240m.wav';
import sndIncorrect from '../audio/incorrect100m.wav';
import instructA1 from '../audio/instructions2_tmtA1_allison_low.mp3';
import instructA2 from '../audio/instructions2_tmtA2_allison_low.mp3';
import instructB from '../audio/instructions2_tmtB_allison_low.mp3';
import '../../../App.css';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { tmtComplete } from '../../../actions/TMTComplete';
//import { buildQueries } from '@testing-library/react';
import PatientQuestionnaire from '../../../PatientQuestionnaire'

class TMT extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showInstructions: true,
			boxes: this.conformation1,
			tmtTestCompleted: false,
			instructAudioFinished: false
		}
	}

  // USED FOR TESTING ONLY
	debugMode = false
	// The data that eventually gets sent to AWS
	data = [{ ver: 'TMT JS v1.05 (3/30/21)', date: Date() }]  //First element of the data array has task version info (title, version, revdate) and test date/time
	clicks = []

	targetCount1 = (this.props.testMode==='Demo') ? 4 : 8   // target count for stage A1 (normally 8)
	targetCount2 = (this.props.testMode==='Demo') ? 8 : 26  // target count for stages A2 & B (normally 26)
	conformationFull = this.pickconformations()
	conformation1 = this.conformationFull.slice(0, this.targetCount1) 
	conformation2 = this.conformationFull.slice(0, this.targetCount2)
    labelsTrailsA1 = ['1','2','3','4','5','6','7','8']  // 10 targets for Trails A
	labelsTrailsA2 = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26']  // 10 targets for Trails A
	labelsTrailsB = ['1','A','2','B','3','C','4','D','5','E','6','F','7','G','8','H','9','I','10','J','11','K','12','L','13','M'] // 26 targets for Trails B
	labelsCurrent = this.labelsTrailsA1

	roundTicks0 = this.props.stageA1Ticks
	roundTicks = this.roundTicks0
	dat = this.datInit() 
	datA1 = this.datInit() 
	datA2 = this.datInit() 
	datB = this.datInit() 
	previousClick = { index: -1, source: 0 }
	
	currentStage = 'A1'
	instructAudioStarted = false
	roundStartTime = null        
	testStartTime = Date.now()   // Since clicking correct boxes can add extra time, we use js Dates to correctly calculate time     
	myAudio = this.setupWebAudio()
	
	static defaultProps = {  // Constant Param Settings 
        timerInterval: 10,   // the timer tick value in msec, i.e. the interval used for all setinterval timers
		postTapTime: 60,     // persistence time for feedback from the last target in each stage in timer ticks
		stageA1Ticks: 3000,  // note that these time values are for 10msec chunks/ticks, based on timerInterval above used in setInterval() below
		stageA2Ticks: 6000, 
		stageBTicks: 7500,
		instructTicksA1: 1100,   // Stage A1 voice instructions are about 11sec long (latency unit start button enabled)
		instructTicksA2: 700,    // Stage A2 voice instructions are about 7sec long
		instructTicksB: 1600,    // Stage B voice instructions are about 16sec long
	}

	componentDidMount() {	
	} 

	datInit() {
		return {nCorrect: 0, nIncorrect: 0, tInstruct: 0, tPerform: 0}
	}

	// SETUP for using Web Audio API: defining an AudioContext, and loading a buffer with decoded raw sound data
	setupWebAudio() {
		const sndList = [sndCorrect, sndIncorrect, sndDone] //, instruct1, instruct3] 
		const myAudio = {context: null, buffer: [], bufferLoaded: [], iCorrect: 0, iIncorrect: 1, iEndRound: 2, iInstructA: 3, iInstructB: 4 }	
		window.AudioContext = window.AudioContext||window.webkitAudioContext;
		myAudio.context = new AudioContext();//myAudio.context = new AudioContext(window.AudioContext || window.webkitAudioContext);
		for (let i = 0; i < sndList.length; i++) {
			myAudio.bufferLoaded[i]=false                   //initialization allowing us to check beffer length later on
			const request = new XMLHttpRequest();
			request.open('GET', sndList[i], true);
			request.responseType = 'arraybuffer';
			request.onload = function() {
				myAudio.context.decodeAudioData(request.response, function(theBuffer) {        // Decode asynchronously
				myAudio.buffer[i] = theBuffer;
				myAudio.bufferLoaded[i]=true                   //initialization allowing us to check beffer length later on
	  			}, e => console.log('sound load message: [' + i + '] ' + e) );
			}
			request.send();
		}
		return myAudio
	}

	playWebAudio(bufferIndex) {
		const source = this.myAudio.context.createBufferSource()
		source.buffer = this.myAudio.buffer[bufferIndex]
		source.connect(this.myAudio.context.destination)
		source.start(0)
	}

	playSound(snd) {
		const correctAudio = new Audio(snd)
		correctAudio.play()
	}

	pickconformations() {
		const confs =  [[{'x': 730, 'y': 550}, {'x': 860, 'y': 640}, {'x': 130, 'y': 80}, {'x': 300, 'y': 590}, {'x': 870, 'y': 350}, {'x': 580, 'y': 500}, {'x': 760, 'y': 290}, {'x': 200, 'y': 420}, {'x': 340, 'y': 70}, {'x': 550, 'y': 200}, {'x': 370, 'y': 360}, {'x': 550, 'y': 390}, {'x': 690, 'y': 60}, {'x': 70, 'y': 420}, {'x': 30, 'y': 230}, {'x': 160, 'y': 580}, {'x': 760, 'y': 400}, {'x': 210, 'y': 310}, {'x': 440, 'y': 480}, {'x': 330, 'y': 190}, {'x': 50, 'y': 600}, {'x': 860, 'y': 150}, {'x': 570, 'y': 610}, {'x': 520, 'y': 90}, {'x': 440, 'y': 620}, {'x': 440, 'y': 210}],
						[{'x': 340, 'y': 630}, {'x': 110, 'y': 310}, {'x': 720, 'y': 420}, {'x': 740, 'y': 240}, {'x': 20, 'y': 40}, {'x': 90, 'y': 550}, {'x': 440, 'y': 330}, {'x': 200, 'y': 160}, {'x': 440, 'y': 50}, {'x': 720, 'y': 640}, {'x': 640, 'y': 80}, {'x': 310, 'y': 520}, {'x': 850, 'y': 470}, {'x': 410, 'y': 170}, {'x': 770, 'y': 100}, {'x': 220, 'y': 310}, {'x': 480, 'y': 490}, {'x': 260, 'y': 30}, {'x': 60, 'y': 180}, {'x': 560, 'y': 610}, {'x': 830, 'y': 630}, {'x': 560, 'y': 250}, {'x': 590, 'y': 360}, {'x': 110, 'y': 420}, {'x': 700, 'y': 530}, {'x': 850, 'y': 330}],
						[{'x': 830, 'y': 440}, {'x': 620, 'y': 430}, {'x': 70, 'y': 440}, {'x': 260, 'y': 60}, {'x': 610, 'y': 290}, {'x': 440, 'y': 580}, {'x': 300, 'y': 420}, {'x': 410, 'y': 60}, {'x': 590, 'y': 100}, {'x': 170, 'y': 200}, {'x': 590, 'y': 550}, {'x': 280, 'y': 620}, {'x': 750, 'y': 230}, {'x': 290, 'y': 220}, {'x': 470, 'y': 440}, {'x': 140, 'y': 80}, {'x': 750, 'y': 640}, {'x': 140, 'y': 320}, {'x': 60, 'y': 620}, {'x': 860, 'y': 20}, {'x': 470, 'y': 230}, {'x': 870, 'y': 140}, {'x': 750, 'y': 100}, {'x': 860, 'y': 570}, {'x': 180, 'y': 490}, {'x': 860, 'y': 330}],
						[{'x': 330, 'y': 470}, {'x': 580, 'y': 250}, {'x': 510, 'y': 620}, {'x': 270, 'y': 310}, {'x': 120, 'y': 590}, {'x': 670, 'y': 420}, {'x': 90, 'y': 250}, {'x': 610, 'y': 130}, {'x': 250, 'y': 190}, {'x': 870, 'y': 580}, {'x': 490, 'y': 490}, {'x': 160, 'y': 470}, {'x': 830, 'y': 180}, {'x': 210, 'y': 80}, {'x': 730, 'y': 290}, {'x': 760, 'y': 20}, {'x': 340, 'y': 40}, {'x': 810, 'y': 410}, {'x': 80, 'y': 60}, {'x': 850, 'y': 290}, {'x': 550, 'y': 20}, {'x': 280, 'y': 580}, {'x': 710, 'y': 600}, {'x': 400, 'y': 320}, {'x': 460, 'y': 170}, {'x': 20, 'y': 460}],
						[{'x': 240, 'y': 630}, {'x': 600, 'y': 90}, {'x': 690, 'y': 520}, {'x': 870, 'y': 280}, {'x': 320, 'y': 140}, {'x': 110, 'y': 170}, {'x': 650, 'y': 390}, {'x': 490, 'y': 290}, {'x': 820, 'y': 550}, {'x': 180, 'y': 410}, {'x': 450, 'y': 20}, {'x': 40, 'y': 490}, {'x': 380, 'y': 630}, {'x': 750, 'y': 80}, {'x': 320, 'y': 400}, {'x': 530, 'y': 590}, {'x': 80, 'y': 20}, {'x': 20, 'y': 600}, {'x': 430, 'y': 400}, {'x': 70, 'y': 360}, {'x': 640, 'y': 250}, {'x': 370, 'y': 510}, {'x': 350, 'y': 260}, {'x': 870, 'y': 130}, {'x': 240, 'y': 260}, {'x': 800, 'y': 430}],
						[{'x': 520, 'y': 410}, {'x': 840, 'y': 290}, {'x': 310, 'y': 380}, {'x': 670, 'y': 440}, {'x': 280, 'y': 90}, {'x': 490, 'y': 30}, {'x': 530, 'y': 570}, {'x': 250, 'y': 210}, {'x': 740, 'y': 180}, {'x': 440, 'y': 280}, {'x': 70, 'y': 160}, {'x': 840, 'y': 460}, {'x': 450, 'y': 170}, {'x': 830, 'y': 50}, {'x': 60, 'y': 20}, {'x': 140, 'y': 300}, {'x': 700, 'y': 610}, {'x': 630, 'y': 250}, {'x': 230, 'y': 550}, {'x': 110, 'y': 430}, {'x': 20, 'y': 540}, {'x': 670, 'y': 70}, {'x': 400, 'y': 490}, {'x': 30, 'y': 280}, {'x': 340, 'y': 600}, {'x': 830, 'y': 610}],
						[{'x': 660, 'y': 520}, {'x': 810, 'y': 180}, {'x': 230, 'y': 210}, {'x': 280, 'y': 70}, {'x': 430, 'y': 340}, {'x': 710, 'y': 400}, {'x': 20, 'y': 520}, {'x': 820, 'y': 390}, {'x': 290, 'y': 550}, {'x': 290, 'y': 400}, {'x': 450, 'y': 70}, {'x': 110, 'y': 170}, {'x': 540, 'y': 530}, {'x': 650, 'y': 40}, {'x': 350, 'y': 180}, {'x': 600, 'y': 260}, {'x': 760, 'y': 30}, {'x': 20, 'y': 30}, {'x': 180, 'y': 330}, {'x': 420, 'y': 570}, {'x': 180, 'y': 560}, {'x': 480, 'y': 210}, {'x': 790, 'y': 560}, {'x': 540, 'y': 400}, {'x': 50, 'y': 310}, {'x': 160, 'y': 30}],
						[{'x': 330, 'y': 500}, {'x': 550, 'y': 170}, {'x': 780, 'y': 470}, {'x': 300, 'y': 370}, {'x': 790, 'y': 150}, {'x': 130, 'y': 430}, {'x': 540, 'y': 490}, {'x': 360, 'y': 140}, {'x': 170, 'y': 600}, {'x': 750, 'y': 280}, {'x': 230, 'y': 50}, {'x': 220, 'y': 250}, {'x': 720, 'y': 580}, {'x': 50, 'y': 60}, {'x': 460, 'y': 320}, {'x': 850, 'y': 40}, {'x': 610, 'y': 280}, {'x': 390, 'y': 610}, {'x': 650, 'y': 470}, {'x': 620, 'y': 20}, {'x': 470, 'y': 60}, {'x': 20, 'y': 450}, {'x': 850, 'y': 640}, {'x': 340, 'y': 20}, {'x': 110, 'y': 170}, {'x': 100, 'y': 280}],
						[{'x': 360, 'y': 490}, {'x': 700, 'y': 580}, {'x': 190, 'y': 610}, {'x': 700, 'y': 220}, {'x': 260, 'y': 370}, {'x': 240, 'y': 50}, {'x': 90, 'y': 70}, {'x': 870, 'y': 100}, {'x': 470, 'y': 540}, {'x': 80, 'y': 180}, {'x': 30, 'y': 410}, {'x': 500, 'y': 270}, {'x': 210, 'y': 220}, {'x': 490, 'y': 150}, {'x': 670, 'y': 70}, {'x': 50, 'y': 300}, {'x': 860, 'y': 220}, {'x': 870, 'y': 430}, {'x': 330, 'y': 160}, {'x': 520, 'y': 390}, {'x': 640, 'y': 340}, {'x': 470, 'y': 40}, {'x': 380, 'y': 360}, {'x': 590, 'y': 600}, {'x': 140, 'y': 440}, {'x': 60, 'y': 640}],
						[{'x': 560, 'y': 230}, {'x': 330, 'y': 210}, {'x': 720, 'y': 500}, {'x': 500, 'y': 560}, {'x': 220, 'y': 150}, {'x': 160, 'y': 40}, {'x': 250, 'y': 480}, {'x': 790, 'y': 280}, {'x': 390, 'y': 630}, {'x': 550, 'y': 50}, {'x': 650, 'y': 340}, {'x': 70, 'y': 620}, {'x': 830, 'y': 590}, {'x': 270, 'y': 600}, {'x': 520, 'y': 380}, {'x': 210, 'y': 320}, {'x': 720, 'y': 60}, {'x': 660, 'y': 630}, {'x': 380, 'y': 70}, {'x': 380, 'y': 480}, {'x': 860, 'y': 450}, {'x': 30, 'y': 140}, {'x': 350, 'y': 360}, {'x': 80, 'y': 500}, {'x': 870, 'y': 30}, {'x': 50, 'y': 320}]
		]
		confs.sort(() => Math.random() - 0.5);                   
		return confs[0]   //pick a conformation row at random (row 0 of the RANDOMLY RESORTED array)
	}

	// THE CLICK ON EVERY BUTTON CLICK
	boxClick(value, index, source, e) {
		const { boxes } = this.state
		const { myAudio, labelsCurrent } = this
		//console.log(['tgt',index, 'source',source, 'time', Date.now() - this.roundStartTime])
		let gotNewClick = false
		if (!(index === this.previousClick.index && source !== this.previousClick.source)) { 
			gotNewClick = true
			this.previousClick = { index: index, source: source }
		}
		if ( !boxes[index].correct && gotNewClick ) {  //reject clicks before test is ready for them OR if item is already clicked
			boxes[index].isClicked = true
			boxes[index].time = (Date.now() - this.roundStartTime) / 1000
			if(boxes[index].label === labelsCurrent[this.dat.nCorrect]) {    // check if correct!
				boxes[index].correct = true
				this.dat.nCorrect++
				if (this.dat.nCorrect < boxes.length) {
					this.playWebAudio(myAudio.iCorrect)
				} else {
					this.playWebAudio(myAudio.iEndRound)
					this.roundTicks = this.props.postTapTime  // ROUND COMPLETE!!
				}
			} else {
				boxes[index].correct = false
				this.dat.nIncorrect++
				this.playWebAudio(myAudio.iIncorrect)
			}	
			this.setState({ boxes: boxes })
			let clickXY = { xc: e.nativeEvent.offsetX, yc: e.nativeEvent.offsetY }
			const{x,y,time,correct,label} = boxes[index]
			this.clicks.push({ correct: correct, label: label, time: time, x: x, y: y, ...clickXY })		
		}
	}

	// USED TO SHOW INSTRUCTIONS BEFORE THE DIFFERENT STAGES OF TESTING
	toggleInstructions = () => {
		this.instructAudioStarted = false
		this.setState({ 
			instructAudioFinished: false,
			showInstructions: false
		})
		this.roundStart()
	}

	// CALLED AT THE BEGINNING OF EACH ROUND
	roundStart() {
		const { currentStage } = this
		const { stageA1Ticks ,stageA2Ticks ,stageBTicks } = this.props
		this.initializeBoxes()                                  // Shuffle Dogs and Cats
		this.playWebAudio(this.myAudio.iEndRound)
		this.dat = this.datInit()    // initialize summary data
		this.dat.tInstruct = (Date.now() - this.instructStartTime)/1000
		this.roundStartTime = Date.now()
		this.roundTicks0 = (currentStage === 'A1') ? stageA1Ticks:(currentStage === 'A2') ? stageA2Ticks:stageBTicks  
		this.roundTicks = this.roundTicks0   // reset round Ticks
		this.roundInterval = setInterval(() => {  // TIMER THAT RUNS FOR EACH ROUND/STAGE (A1, A2, B)
			this.roundTicks--
			if (this.roundTicks <= 0){
					clearInterval(this.roundInterval)
					this.roundEnd()
				}
		}, this.props.timerInterval)
	}
    
	// Performs a 2-level clone for an array of objects (objects fields should be individual values)
	cloneArray(x) {    
		let xClone=[]
		for (let i = 0; i < x.length; i++){
			xClone[i]={...x[i]}
		}
		return xClone
	}

	// CALLED AT THE END OF EACH ROUND
	roundEnd() {
		const { boxes } = this.state
		const { currentStage, roundTicks0 } = this
		this.dat.tPerform = (this.clicks.length>0) ? this.clicks[this.clicks.length-1].time : null
		const { nCorrect, nIncorrect, tInstruct, tPerform} = this.dat
		
		const goal = boxes.length
		const T = (nCorrect === goal) ? tPerform : roundTicks0*this.props.timerInterval/1000
		const T0 = (this.clicks.length>0) ? this.clicks[0].time : null
		const tpm0 = this.round(60*(nCorrect)/(T),2)
		const tpm1 = this.round(60*(nCorrect-1)/(T- T0),2)
		this.data.push({ boxInfo: this.cloneArray(boxes) })             // Add the box conformation to the data stream
		this.data.push({ clickInfo: this.cloneArray(this.clicks) })     // Add the click array to the data stream
		this.data.push({ goal: goal, correct: nCorrect, incorrect: nIncorrect, instructTime: this.round(tInstruct,1), actionTime: this.round(tPerform,1), tpm0: tpm0, tpm1: tpm1 })    
		this.clicks = []
		if (currentStage === 'A1') {
			this.datA1 = this.dat
			this.labelsCurrent = this.labelsTrailsA2
			this.currentStage = 'A2'
			this.setState({ showInstructions: true })
		} else if (currentStage === 'A2') {
			this.datA2 = this.dat
			this.labelsCurrent = this.labelsTrailsB
			this.currentStage = 'B'
			this.setState({ showInstructions: true })
		} else {
			this.datB = this.dat
			this.tTest = this.round((Date.now()-this.testStartTime)/1000,1)
			this.setState({ tmtTestCompleted: true })
			this.data.push({ testDuration: this.tTest })
		}
	}	

	toggle = () => {
        this.props.tmtComplete(this.data)
	}

	// RESETS THE BOXES BACK TO THEIR ORIGINAL STATE
	initializeBoxes() {
		const { currentStage, labelsCurrent } = this
		const newBoxes = (currentStage === 'A1') ? this.conformation1:this.conformation2  // Pick the correct conformation
		newBoxes.sort(() => Math.random() - 0.5);
		for(let i = 0; i < newBoxes.length; i++){
			newBoxes[i].isClicked = false
			newBoxes[i].correct = null
			newBoxes[i].label = labelsCurrent[i]
		}
		this.setState({ boxes: newBoxes })
	}

	speakInstructions = () => {
		const { currentStage } = this
		const { instructTicksA1, instructTicksA2, instructTicksB } = this.props
		if (!this.instructAudioStarted) {
			this.playSound((currentStage === 'A1') ? instructA1 : (currentStage === 'A2') ? instructA2 : instructB)
			this.instructStartTime = Date.now()
			this.instructAudioStarted = true  // Note: cannot use a state var with setstate() for this, as it wouldn't update immediately!!
			this.setState({showInstructions: this.state.showInstructions})  // Hack to trigger render() (even though showInstructions unchanged)
			let ticks = (currentStage === 'A1') ? instructTicksA1 : (currentStage === 'A2') ? instructTicksA2 : instructTicksB
			this.instructInterval = setInterval(() => {  // TIMER THAT RUNS TO ENABLE START BUTTON AFTER VOICE INSTRUCTIONS FINISHED
				ticks--
				if (ticks <= 0){
						clearInterval(this.instructInterval)
						this.setState({ instructAudioFinished: true })
					}
			}, this.props.timerInterval)
		}
	}
	
	renderInstructions(stage) {
		const { instructAudioStarted } = this
		const { instructAudioFinished } = this.state
		if (stage === 'A1') {
			this.speakInstructions()
			return (
				<Modal centered={true} isOpen={true}>
					<ModalBody className="text-center">There will be numbers in circles on the screen. Please touch them in increasing order. 
													   Start at <b>1</b>, then go to <b>2</b>, then <b>3</b>, and so on. Work as quickly and accurately as you can.
					</ModalBody>
					<ModalFooter className="d-flex justify-content-center">
					<Button disabled={!instructAudioFinished} color="primary" onClick={this.toggleInstructions}>Start</Button>
					</ModalFooter>
				</Modal>)
		} if (stage === 'A2' && instructAudioStarted) {
			return (
				<Modal centered={true} isOpen={true}>
					<ModalBody className="text-center">Now there will be many more numbers on the screeen. Please touch them in increasing order again,
													   working as quickly and accurately as you can.
					</ModalBody>
					<ModalFooter className="d-flex justify-content-center">
					<Button disabled={!instructAudioFinished} color="primary" onClick={this.toggleInstructions}>Start</Button>
					</ModalFooter>
				</Modal>)
		} else if (stage === 'B' && instructAudioStarted) {
			return (
				<Modal centered={true} isOpen={true}>
					<ModalBody className="text-center">Now there will be numbers and letters in circles on the screen. Please touch them in alternating order. 
													   Start at number <b>1</b>, then go to the first letter, <b>A</b>, then go to the next number, <b>2</b>, 
													   and then the next letter, <b>B</b>, and so on. Work as quickly and accurately as you can.
					</ModalBody>
					<ModalFooter className="d-flex justify-content-center">
					<Button disabled={!instructAudioFinished} color="primary" onClick={this.toggleInstructions}>Start</Button>
					</ModalFooter>
				</Modal>)
		} else if ( (!instructAudioStarted) && (stage === 'A2' || stage === 'B') ) {  // Popup directly linked to instruction voice over so iOS deosn't suppress it!!!
			const partNumber = (stage === 'A2') ? '1':'2'
			//const instructAudioStarted = (stage === 'A2') ? this.instructA2AudioStarted:this.instructBAudioStarted
			return (
				<Modal centered={true} isOpen={true}>
					<ModalBody className="text-center">Part {partNumber} Complete!</ModalBody>
					<ModalFooter className="d-flex justify-content-center">
					<Button color="primary" onClick={this.speakInstructions}>Proceed</Button>
					</ModalFooter>
				</Modal>)
		}
	}

	renderBox(value, index, color_array, opacity1) {
		const color1 = (index===0) ? color_array[1]:color_array[0]
		const h2text = (index===0 && !value.correct) ? 'Start':null
		const padding1 = (index===0 && !value.correct) ? '15px 10px':'12px'
		return(
			<div
				style={{
				lineHeight: '80px',
				position: 'absolute',
				width: '80px',
				height: '80px',
				top: value.y,
				left: value.x,
				backgroundColor: "white", 
				textAlign: 'center',
				padding: padding1,
				borderRadius: '50%',
				borderStyle: 'solid',
				borderColor: color1,
				color: color1,
				opacity: opacity1								
				}}
				onTouchStart={(e) => this.boxClick(value, index, 1, e)}
				onMouseDown={(e) => this.boxClick(value, index, 2, e)}
				//onClick={(e) => this.boxClick(value, index, 3, e)}
				>
				<h1>{value.label}</h1>
				<h4>{h2text}</h4>
			</div>)
	}

	round(x,n){return Math.round(x*(10**n))/(10**n)}  //rounds x to the nth decimal place 
	
	render() {
		const { entryCode } = this.props
		const { showInstructions, boxes, tmtTestCompleted } = this.state
		const { currentStage } = this
		//document.body.ontouchmove = (e) => { e.preventDefault(); return false; }
		//note the double render (actually, the common initial render, regardless of final color) under boxes.map below is needed to make the scroll lock work (unknown why??)
		return (
			<div>
				<div className="CatsAndDogs">
					{boxes.map((value, index) => 
						<div>
							{!showInstructions && this.renderBox(value,index,['Blue','Red'], 1)}  
							{!showInstructions && value.correct  && this.renderBox(value,index,['Lightgrey','Lightgrey'], 1)}					
						</div>
					)}
				</div>
				{!tmtTestCompleted && showInstructions && this.renderInstructions(currentStage)}
				{tmtTestCompleted && this.props.testDone()}
				{tmtTestCompleted && this.props.tmtComplete(this.data) && <PatientQuestionnaire entryCode={entryCode} testID='tmt'/>}
				{this.debugMode && <p> {this.roundTicks} </p>}
    		</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ tmtComplete: tmtComplete}, dispatch)
}

export default connect(null, mapDispatchToProps)(TMT);