// import CatsAndDogs from './tests/catsanddogs/components/CatsAndDogs';
import TMT from './tests/tmt/components/TMT';
import CPT from './tests/cpt/components/CPT';
import MEM from './tests/memory/components/mem';
import CatsAndDogsNoMem from './tests/catsanddogsnomemory/components/CatsAndDogsNoMem';
// import CatsAndDogsNoMemfeb19 from './tests/catsanddogsnomemory/components/CatsAndDogsNoMemfeb19';
import CatsAndDogsNoMemPractice from './tests/practice/components/CatsAndDogsNoMemPractice';
import Results from './Results';
import SendData from './SendData';
import Reset from './Reset';
import AdminQuestionnaire from './AdminQuestionnaire';

export default {
			'home' : {
				'path': '/',
				'text': 'Home',
				'component': null
			},
			'test_routes' : [
				/*
				{'path': '/catsanddogs',
				 'text': 'Cats and Dogs with Memory',
				 'component': CatsAndDogs
				},
				*/
				{'path': '/catsanddogsnomem',
				 'text': 'Cats and Dogs',
				 'component': CatsAndDogsNoMem
				},
				/*
				{'path': '/catsanddogsnomemfeb19',
				 'text': 'Cats and Dogs Feb19',
				 'component': CatsAndDogsNoMemfeb19
				},
				*/
				{'path': '/tmt',
				 'text': 'TMT',
				 'component': TMT
				},
				{'path': '/cpt',
				 'text': 'CPT',
				 'component': CPT
				},
				{'path': '/mem',
				 'text': 'MEM',
				 'component': MEM
				}
			],
			'practice_routes' : [
				{'path': '/catsanddogspractice',
		 		 'text': 'Cats and Dogs Practice',
		 		 'component': CatsAndDogsNoMemPractice
				}
			],
			'results' : {'path': '/results',
				 'text': 'View Results',
				 'component': Results
			},
			'senddata' : {'path': '/senddata',
				'text': 'Send Data',
				'component': SendData
	   		},
	   		'questionaire' : {'path': '/adminquestionnaire',
	   			'text': 'Questionnaire',
	   			'component': AdminQuestionnaire
	   		},
	   		'reset' : {'path': '/reset',
	   			'text': 'Reset Tests',
	   			'component': Reset
	   		}
		}