import React from 'react';
import { Row, Col, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { browserName } from 'react-device-detect';

class Anart extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			audio: null,
			showInstructions: true
		}
	}

	mediaRecorder = null
	data = []
	stream = null
	options = null

	async componentDidMount() {
		this.targetElement = document.querySelector('.ButtonStrip');
		//disableBodyScroll(this.targetElement) //lock/prevent page scrolling so that the "game board" doesn't move when accidentally dragging the screen during testing
		document.body.ontouchmove = (e) => { e.preventDefault(); return false; }
		this.stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
		if (browserName === 'Chrome') {
			this.options = { mimeType: 'audio/webm' }
		} else {
			this.options = { mimeType: 'audio/mp4' }
		}
		this.mediaRecorder = new MediaRecorder(this.stream, this.options);
		this.mediaRecorder.ondataavailable = (event) => {
			if (event.data && event.data.size > 0) {
				this.data.push(event.data);
			}
		}
		this.mediaRecorder.start(5)
	}

	upload = (filename, body) => {
		return fetch('https://0dmiwnt6r8.execute-api.us-east-1.amazonaws.com/default/iBoca3UploadAudio/', {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: JSON.stringify({"filename" : filename, "body" : body })
		})
			.then(response => response.json())
			.then(data => {
				console.log('upload successful:', data);
		})
			.catch(error => {
				console.log('upload failed:', error);
				throw error;
			})
	}

	blobToBase64 = (blob) => {
		return new Promise((resolve, _) => {
		  const reader = new FileReader();
		  reader.onloadend = () => resolve(reader.result);
		  reader.readAsDataURL(blob);
		});
	  }

	async complete() {
		const { entryCode } = this.props
		this.mediaRecorder.stop()
		this.stream.getTracks().forEach(track => track.stop());
		let filename = entryCode + '.webm'
		if (browserName !== 'Chrome') {
			filename = entryCode + '.mp4'
		}
		const blob = new Blob(this.data, {"type" : this.options.mimeType })
		const b64blob = await this.blobToBase64(blob)
		this.upload(filename, b64blob)
	}

	words = ["ACHE", "AISLE", "CAPON", "DEBT", "CHORD", "HEIR", "DENY", "BOUQUET", "CAPRICE", "GAUGE", "WORSTED", "DEPOT", "NAUSEA", "NAIVE", "SUBTLE", "PUGILIST", "FETAL", "BLATANT", "PALCEBO",
		"HAITUS", "SIMILE", "MERINGUE", "SIEVE", "CHASSIS", "CELLIST", "ALGAE", "SUPERFLUOUS", "CHAMOIS", "THYME", "APROPOS", "VIRULENT", "ZEALOT", "FACADE", "CABAL", "ABSTEMIOUS", "DETENTE", "SCION",
		"PAPYRUS", "QUADRUPED", "PRELATE", "EPITOME", "BEATIFY", "HYPERBOLE", "IMBROGLIO", "SYNCOPE"]

	renderRows = (start) => {
		const items = []
		for (let i = start; i < start + 5; i++) {
			items.push(<Col className="m-2"><b>{i + 1}. {this.words[i]}</b></Col>)
		}
		return items
	}
	
	hideInstructions = () => {
		this.setState({
			showInstructions: false
		})
	}

	render = () => {
		const { group } = this.props
		const { showInstructions } = this.state
		const items = []
		for (let i = 0; i < 9; i++) {
			items.push(<Row>{this.renderRows(i * 5)}</Row>)
		}
		return (
			<>
			{ showInstructions ?
				<Modal centered={true} isOpen={true}>
					<ModalBody className="text-center">
						<p className="my-2">Please Read the following words aloud in order</p>
					</ModalBody>
					<ModalFooter className="d-flex justify-content-center">
						<Button color="primary" onClick={this.hideInstructions}>Start</Button>
					</ModalFooter>
				</Modal>
			:
			<div>
				<div className="Anart">
					{items}
				</div>
				<ModalFooter className="d-flex justify-content-center">
					<Link to={group === 1 ? '/auto/d-cog-short' : '/auto/d-cog-long'}>
						<Button color="primary" onClick={() => this.complete()}>Complete</Button>
					</Link>
				</ModalFooter>
			</div>
			}
			</>
		)
	}
}

function mapStateToProps(state) {
	return {
		group: state.auto.group
	}
}

export default connect(mapStateToProps)(Anart);