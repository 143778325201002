import React from 'react';

import { Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route} from 'react-router-dom';
import IntroConsentContainer from './IntroConsentContainer';
import CatsAndDogsNoMem from './tests/catsanddogsnomemory/components/CatsAndDogsNoMem';
import Anart from './tests/Anart';
import Demographics from './Demographics'
import TestingComplete from './TestingComplete'
import './App.css'
import { bindActionCreators } from 'redux';
import { autoGroupEntered } from './actions/AUTOComplete'

class AutoAppContainer extends React.Component {
	
	componentDidMount() {	
		this.targetElement = document.querySelector('.ButtonStrip');
		//disableBodyScroll(this.targetElement) //lock/prevent page scrolling so that the "game board" doesn't move when accidentally dragging the screen during testing
		document.body.ontouchmove = (e) => { e.preventDefault(); return false; }
		this.selectGroup()
	} 

	// PLACE THE USER INTO GROUP 1 OR 2 FOR THE TEST PATH
	selectGroup() {
		// CURRENTLY GROUP PLACEMENT IS A 50/50 CHANCE
		let rand = Math.floor(Math.random() * 2);
		// IF RAND == 1 GROUP 1, ELSE GROUP 2
		if(rand) {
			this.props.autoGroupEntered(1)
		} else {
			this.props.autoGroupEntered(2)
		}
	}

	// GROUP 1 PROCEDURE
	// INTRO/CONSENT -> D-COG SHORT -> ANART -> D-COG SHORT -> DEMOGRAPHICS -> D-COG LONG

	// GROUP 2 PROCEDURE
	// INTRO/CONSENT -> D-COG LONG -> ANART -> D-COG LONG -> DEMOGRAPHICS -> D-COG SHORT

	shortTime = 0.1
	longTime = 1.0
  	
	render = () => {
	const { entryCode } = this.props
		return(
			<BrowserRouter>
				<Container>
					<Row noGutters="true">
						<div className="TestWindow">
							<Switch>
								<Route exact path='/auto'>
									<IntroConsentContainer entryCode={entryCode} />
								</Route>
								<Route path="/auto/d-cog-short">
									<CatsAndDogsNoMem time={this.shortTime} timeMode={'short'}/>
								</Route>
								<Route path="/auto/anart">
									<Anart entryCode={entryCode}/>
								</Route>
								<Route path="/auto/demographics">
									<Demographics />
								</Route>
								<Route path="/auto/d-cog-long">
									<CatsAndDogsNoMem time={this.longTime} timeMode={'long'}/>
								</Route>
								<Route path="/auto/complete">
									<TestingComplete entryCode={entryCode} />
								</Route>
							</Switch>
		{/*					<div>
							<Progress multi>
								<Progress bar value="25"> TEST </Progress>
								<Progress bar value="50"> TEST2 </Progress>
								<Progress bar value="75"> TEST2 </Progress>
							</Progress>
							</div>*/}
						</div>
					</Row>
				</Container>
			</BrowserRouter>
		)
	}
}

function mapStateToProps(state) {
	return {
		group : state.auto.group,
	}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ autoGroupEntered: autoGroupEntered }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoAppContainer);